import Vue from "vue";
import App from "./App";
import router from "./router/index";
import store from "./router/store";
import helpers from "./stores/helpers";
import Axios from "axios";
import PaperDashboard from "./plugins/paperDashboard";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import { firestorePlugin } from "vuefire";
import firebase from "firebase";
require("firebase/firestore");

import "vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css";
import "vue-notifyjs/themes/default.css";

const datepickerOptions = {
	colors: {
		selected: "#F38E24",
		inRange: "#fbddbd",
		selectedText: "#fff",
		text: "#565a5c",
		inRangeBorder: "#f9c691",
		disabled: "#fff",
		hoveredInRange: "#fbddbd"
	},
	texts: {
		apply: "Select"
	}
};
Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(firestorePlugin);
Vue.use(PaperDashboard);

//firestore

// const firebaseApp = firebase.initializeApp({
// 	apiKey: "AIzaSyB-nyFikaSS5TIeBjl28eM0fsaPYX7TJg4",
// 	authDomain: "ionic-sample-b8444.firebaseapp.com",
// 	databaseURL: "https://ionic-sample-b8444.firebaseio.com",
// 	projectId: "ionic-sample-b8444",
// 	storageBucket: "ionic-sample-b8444.appspot.com",
// 	messagingSenderId: "427719161744",
// 	appId: "1:427719161744:web:064ef7fcd92db0dab2e515"
// });

//const fs = firebase.firestore(firebaseApp);
//Vue.prototype.$db = fs;
//console.log(Vue.prototype.$db);

Vue.config.productionTip = false;

Vue.prototype.$store = store;
Vue.prototype.$helpers = helpers;
Axios.defaults.headers.common["zumo-api-version"] = "2.0.0";
//Axios.defaults.headers.common['force_delay'] = '500';
Axios.defaults.baseURL = "https://femaapp.azurewebsites.nett/";
//Axios.defaults.baseURL = 'http://localhost:49663/';

firebase.auth().onAuthStateChanged(user => {
	//console.log("onAuthStateChanged", user);
	if (user) {
		store.dispatch("findUserAndLogin", {
			user: user,
			resolve: () => {
				router.push("/home").catch(err => {});
			},
			reject: err => {
				console.log(err);
			}
		});
	} else {
		router.push("/login").catch(err => {});
	}
});

Axios.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error.response.status === 401) {
			//router.push("/login");
			router.app.$helpers.dispatch("showError", {
				msg: "TOKEN ERROR!",
				vm: router.app
			});
		}
		return Promise.reject(error);
	}
);

const token = localStorage.getItem("token");
if (token) {
	Axios.defaults.headers.common["x-zumo-auth"] = token;
}

function tokenExpired(exp) {
	return exp < new Date().getTime() / 1000;
}

//Rollbar
var Rollbar = require("vue-rollbar");
Vue.use(Rollbar, {
	//accessToken: "1851558201b549e69f2d2cd489c3925f",
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: true,
	source_map_enabled: true,
	environment: "production",
	payload: {
		client: {
			javascript: {
				code_version: "1.0"
			}
		}
	}
});

/* eslint-disable no-new */
new Vue({
	router,
	render: h => h(App)
}).$mount("#app");
