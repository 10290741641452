import Vue from 'vue';
import Vuex from 'vuex';
import firebase from 'firebase';
import { db } from '@/db';

require('firebase/firestore');

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    loggedInUser: {},
    selectedCustomer: {},
    loggedIn: false,
    name: '',
    role: ''
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, data) {
      state.status = 'success';
      state.loggedInUser = data.user;
      state.role = data.user.Role;
      state.name = data.user.Name;
      state.loggedIn = true;
    },
    select_customer(state, data) {
      state.selectedCustomer = data;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.role = '';
      state.name = '';
      state.loggedIn = false;
      state.loggedInUser = {};
      state.selectedCustomer = {};
    }
  },
  actions: {
    login({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        return firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            return firebase
              .auth()
              .signInWithEmailAndPassword(data.email, data.password)
              .then(res => {
                return dispatch('findUserAndLogin', {
                  user: res.user,
                  resolve: resolve,
                  reject: reject
                });
              })
              .catch(err => {
                console.log('Login error!', err);
                return reject(err.message);
              });
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout');
        firebase.auth().signOut();
        resolve();
      });
    },
    findUserAndLogin({ commit, dispatch }, data) {
      var toFind = data.user.email.toLowerCase();
      return db
        .collection('users')
        .where('Email', '==', toFind)
        .get()
        .then(function(querySnapshot) {
          if (querySnapshot.size !== 1) {
            data.reject('No such user exists');
            return;
          }

          var user = querySnapshot.docs[0].data();

          if (user.Role !== 'Root' && user.Role !== 'Admin' && user.Role !== 'Agent Admin' && user.Role !== 'Client') {
            data.reject('User has insufficient permissions to use the FEMA portal');
            commit('logout');
            firebase.auth().signOut();
            return;
          }

          commit('auth_success', {
            user: user
          });
          data.resolve({
            user: user
          });
        })
        .catch(err => {
          console.log(err);
          data.reject(err);
        });
    },
    selectCustomer({ commit }, data) {
      commit('select_customer', data);
    }
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    isLoggedIn: state => state.loggedIn,
    selectedCustomer: state => state.selectedCustomer,
    authStatus: state => state.status,
    userRole: state => state.role,
    userIsAdmin: state => state.role === 'Root' || state.role === 'Admin',
    userIsAgentAdmin: state => state.role === 'Agent Admin',
    name: state => state.name
  }
});
