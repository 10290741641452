<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-12">
      <card
        :title="title"
        :subTitle="subTitle"
        :showFilter="showFilter"
        @doFilter="doFilter"
        :filterInputValue="filterTerm"
      >
        <div slot="raw-content" class="table-responsive">
          <paper-table
            :data="displayData"
            :loading="loading"
            :columns="columns"
            :hasActions="hasActions"
            :actions="actions"
            :totalItems="totalItems"
            @changePage="changePage"
            @editClick="editClick"
            @deleteClick="deleteClick"
            entityName="Auditor"
            @addClick="addClick"
          ></paper-table>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import odata from "@/services/odata.vue";
import Axios from "axios";
import { PaperTable } from "@/components";
import firebase from "firebase";
import utils from "@/utils";
import store from "@/router/store";
import { db, getCustomer } from "@/db";
require("firebase/firestore");

const tableColumns = ["Name", "Email"];

export default {
  components: {
    PaperTable,
    Loading
  },
  data() {
    return {
      loading: true,
      title: "Auditors",
      subTitle:
        "All the Auditor logins for " +
        store.getters.selectedCustomer.AssignedToAgentName,
      hasActions: true,
      actions: {
        edit: true,
        delete: true
      },
      columns: [...tableColumns],
      allData: [],
      displayData: [],
      filteredData: [],
      isLoading: false,
      currentPage: 1,
      totalItems: 1,
      showFilter: true,
      filterTerm: ""
    };
  },
  firestore() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);

    console.log(
      "store.getters.selectedCustomer.Id",
      store.getters.selectedCustomer.Id
    );
    return {
      allData: db
        .collection("users")
        .where("AssignedToAgentId", "==", store.getters.selectedCustomer.AssignedToAgentId)
        .where("Role", "==", "Auditor")
    };
  },
  watch: {
    allData: {
      immediate: true,
      handler(allData) {
        if (allData.length > 0) {
          this.loading = false;
        }

        this.filterData();
      }
    },
    filterTerm: {
      immediate: true,
      handler(filterTerm) {
        this.filterData();
      }
    }
  },
  methods: {
    changePage(pageNum) {
      this.currentPage = pageNum;
      this.loadPage(pageNum);
    },
    doFilter(filter) {
      this.filterTerm = filter;
      this.loadPage(1);
    },
    editClick(id) {
      this.$router.push(`/editauditor/${id}`);
    },
    addClick(id) {
      this.$router.push(`/addauditor`);
    },
    loadPage(num) {
      this.isLoading = true;
      if (!num) num = this.currentPage;
      let take = 10;
      this.displayData = utils.paginate(this.filteredData, take, num);
      this.isLoading = false;
    },
    filterData() {
      var search = this.filterTerm.toUpperCase();
      this.filteredData = this.allData.filter(f => {
        return (
          (f.Name.toUpperCase().includes(search) ||
            f.Email.toUpperCase().includes(search)) &&
          f.Role !== "Root"
        );
      });

      this.totalItems = this.filteredData.length;
      this.loadPage(1);
    },
    deleteClick(id, name) {
      if (!window.confirm(`Are you sure you want to delete auditor '${name}'?`)) {
        return;
      }
      let self = this;
      this.isLoading = true;
      db.collection("users")
        .doc(id)
        .delete()
        .then(() => {
          self.$helpers.dispatch("showSuccess", {
            msg: "Auditor deleted successfully",
            vm: self
          });
        })
        .catch(err => {
          self.isLoading = false;
          self.$helpers.dispatch("showError2", {
            vm: self,
            err: err
          });
        });
    },
    refreshData() {
      this.loadPage();
    }
  }
};
</script>

<style></style>
