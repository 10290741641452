<template>
  <div>
    <card class="card" title="Reports" :showFilter="false">
      <div class="ml-2 mb-5">
        <div class="row">
          <div class="mt-3 pl-5 col-lg-2 col-md-4 col-sm-12">Select report to run</div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <multiselect
              v-model="selectedReport"
              :options="reportList"
              placeholder="Select report"
              :showLabels="false"
            />
          </div>
        </div>
      </div>
    </card>
    <card
      class="card"
      title="Audit Report Parameters"
      subTitle="Specify parameters and click View:"
      :showFilter="false"
      v-show="selectedReport === 'Audit report'"
    >
      <AuditReportParameters ref="AuditReportParameters" />
    </card>
    <card
      class="card"
      title="Failed Audit Report Parameters"
      subTitle="Specify parameters and click View:"
      :showFilter="false"
      v-show="selectedReport === 'Failed Audit report'"
    >
      <FailedAuditReportParameters ref="FailedAuditReportParameters" />
    </card>
    <card
      class="card"
      title="Asset Report Parameters"
      subTitle="No parameters needed, click View to generate the report"
      :showFilter="false"
      v-show="selectedReport === 'Asset report'"
    >
      <AssetReportParameters ref="AssetReportParameters" />
    </card>

    <card
      class="card"
      title="Tag Report Parameters"
      subTitle="Specify parameters and click View:"
      :showFilter="false"
      v-show="selectedReport === 'Tag report'"
    >
      <TagReportParameters ref="TagReportParameters" />

    </card>

	<card
      class="card"
      title="Progress Report Parameters"
      subTitle="No parameters needed, click View to generate the report"
      :showFilter="false"
      v-show="selectedReport === 'Progress report'"
    >
      <ProgressReportParameters ref="ProgressReportParameters" />
    </card>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import jsreport from "jsreport-browser-client-dist";
import AuditReportParameters from "@/pages/Reports/AuditReportParameters.vue";
import FailedAuditReportParameters from "@/pages/Reports/FailedAuditReportParameters.vue";
import AssetReportParameters from "@/pages/Reports/AssetReportParameters.vue";
import TagReportParameters from "@/pages/Reports/TagReportParameters.vue";
import ProgressReportParameters from "@/pages/Reports/ProgressReportParameters.vue";
import { db, getCustomer } from "@/db";
import utils from "@/utils";
import store from "@/router/store";
Vue.component("multiselect", Multiselect);

export default {
  components: {
    Multiselect,
    AuditReportParameters,
    AssetReportParameters,
    TagReportParameters,
	FailedAuditReportParameters,
	ProgressReportParameters
  },
  data() {
    return {
      reportList: ["Audit report", "Failed Audit report", "Asset report", "Tag report", "Progress report"],
      selectedReport: ""
    };
  },
  // mounted() {
  //   getCustomer()
  //     .collection("audits")
  //     .get()
  //     .then(snap => {
  //       console.log("retrieved audits: ", snap.size); // will return the collection size
  //     });
  // },
  methods: {}
};
</script>

<style scoped>
.card {
  z-index: auto;
}
</style>

<style>
.multiselect__option--selected.multiselect__option--highlight {
  background: #fffcf5;
  color: #f38e24;
}

.multiselect__option--highlight {
  background: #f38e24;
}

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
}
</style>
