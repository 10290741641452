<template>
  <div>
    <img src="/logo3.png" style="width:400px;margin-bottom:20px;" v-if="this.host == 'Kgosi'" />
    <img src="/logo3_cloud.png" style="width:400px;margin-bottom:20px;" v-if="this.host == 'Cloud'" />
    <h4>Welcome to FEMA</h4>
    <span
      v-show="!isInRole('Customer')"
    >Select a customer from the list above to work exclusively with that customer's data.</span>
    <span v-show="isInRole('Customer')">Select Reports on the left to begin.</span>
  </div>
</template>


<script>
import store from "@/router/store.js";
import { getHost } from "../env"


export default {
  data() {
    return {
      host: getHost()
    }
  },
  methods: {
    isInRole(role) {
      return store.getters.userRole === role;
    }
  }
};
</script>
