import firebase from "firebase/app";
import "firebase/firestore";
import store from "./router/store";
import { getFirebaseConfig } from "./env";

let config = getFirebaseConfig();

let _db = firebase
	.initializeApp(config)
	.firestore();

firebase.firestore().settings({
	cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase
	.firestore()
	.enablePersistence()
	.catch(err => console.log("Enabling persistence failed", err));

export const db = _db;

export const getCustomer = () => {
	return _db.collection("customers").doc(store.getters.selectedCustomer.Id);
};
