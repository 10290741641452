<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6 add-question">
      <card title="Add new question" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <label class="control-label">Type</label>
              <select-picker
                emptyText="No results"
                :dropdown-data="questionTypeSelectList"
                placeholder="None"
                v-model="typeString"
                @change="questionTypeChanged"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Question text" v-model="question.Text" required />
            </div>
          </div>
          <div class="row" v-show="this.question.Type === 1">
            <div class="col">
              <label class="control-label">Expected answer</label>
              <select-picker
                emptyText="No results"
                :dropdown-data="expectedAnswerSelectList"
                placeholder="None"
                v-model="question.ExpectedAnswer"
                @change="expectedAnswerChanged"
              />
            </div>
          </div>
          <div class="row">
            <div class="col ml-1">
              <input
                id="hasToAnswer"
                type="checkbox"
                v-model="question.RequiredToAnswer"
                checked="this.question.RequiredToAnswer"
              />
              <label for="hasToAnswer">&nbsp;Question has to be answered</label>
            </div>
          </div>
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3">
              <p-button
                type="danger"
                round
                @click="cancel"
                fa="fal fa-times-octagon"
                class="mr-3"
              >Cancel</p-button>
              <p-button type="info" fa="fal fa-save" round nativeType="submit">Save</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SelectPicker from "vue-bootstrap-selectpicker";
import { db, getCustomer } from "@/db";
import utils from "@/utils";
import store from "@/router/store";

export default {
  components: {
    Loading
  },
  props: {
    assetTypeId: {
      type: String,
      default: ""
    },
    orderNo: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.question.Id = utils.newId();
    this.question.OrderNo = this.$props.orderNo;
    this.question.CustomerId = store.getters.selectedCustomer.Id;
    this.question.CustomerName = store.getters.selectedCustomer.CustomerName;
  },
  data() {
    return {
      isLoading: false,
      assetType: {},
      questionTypeSelectList: [
        {
          text: "Yes/No",
          value: "1"
        },
        {
          text: "Free text",
          value: "2"
        }
      ],
      expectedAnswerSelectList: ["Yes", "No"],
      question: {
        AssetType: "",
        AssetTypeId: "",
        CustomerId: "",
        CustomerName: "",
        ExpectedAnswer: "",
        Id: "",
        OrderNo: 0,
        RequiredToAnswer: false,
        Text: "",
        Type: 2
      }
    };
  },
  firestore() {
    return {
      assetType: getCustomer()
        .collection("assettypes")
        .doc(this.$props.assetTypeId)
    };
  },
  watch: {
    assetType: {
      immediate: true,
      handler(at) {
        if (!at) {
          return;
        }
        this.question.AssetTypeId = at.Id;
        this.question.AssetType = at.AssetTypeName;
      }
    }
  },
  computed: {
    typeString() {
      return this.question.Type === 1 ? "Yes/No" : "Free text";
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;
      getCustomer()
        .collection("questions")
        .doc(this.question.Id)
        .set(this.question)
        .then(() => {
          this.$router.go(-1);
          this.$helpers.dispatch("showSuccess", {
            vm: this,
            msg: "Question created successfully"
          });
        })
        .catch(err => {
          this.isLoading = false;
          this.$helpers.dispatch("showError2", {
            vm: this,
            err: err
          });
        });
    },
    expectedAnswerChanged({ data, text }) {
      this.question.ExpectedAnswer = text;
    },
    questionTypeChanged({ data, text }) {
      this.question.Type = text === "Free text" ? 2 : 1;
      this.question.ExpectedAnswer = text === "Free text" ? "" : "Yes";
    }
  }
};
</script>

<style>
div.add-question .bootstrap-select {
  width: 100% !important;
}
</style>
