<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Edit Client" :subTitle="subTitle" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Name" v-model="user.Name" required />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="email" label="Email" v-model="user.Email" readonly />
            </div>
          </div>
          <!-- <div class="row">
            <div class="col">
              <label class="control-label">Role</label>
              <select-picker
                emptyText="No results"
                :dropdown-data="rolesSelectList"
                placeholder="None"
                v-model="user.Role"
                @change="changeSelect"
                search
              />
            </div>
          </div> -->
          <!-- <div class="row" v-if="user.Role === 'Customer'">
            <div class="col">
              <label class="control-label">Customer</label>
              <select-picker
                :dropdown-data="customersSelectList"
                placeholder="None"
                v-model="user.AssignedToCustomerName"
                @change="changeCustomerSelect"
                search
              />
            </div>
          </div>-->
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3">
              <p-button
                type="danger"
                round
                @click="cancel"
                class="mr-3"
                fa="fal fa-times-octagon"
              >Cancel</p-button>
              <p-button type="info" round fa="fal fa-save" nativeType="submit">Save</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SelectPicker from "vue-bootstrap-selectpicker";
import store from "@/router/store.js";
import { db, getCustomer } from "@/db";

export default {
  components: {
    Loading
  },
  props: {
    Id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      subTitle:
        "Edit a " + store.getters.selectedCustomer.CustomerName + " client login",
      isLoading: false,
      user: {
        AssignedToCustomerId: "",
        AssignedToCustomerName: "",
        AssignedToAgentId: "",
        AssignedToAgentName: "",
        Email: "",
        Id: "",
        Name: "",
        Role: ""
      },
      rolesSelectList: [
        { text: "Auditor", value: "Auditor" },
        { text: "Client", value: "Client" }
      ],
      customersSelectList: [],
      allCustomers: []
    };
  },
  firestore() {
    return {
      allCustomers: db.collection("customers"),
      user: db.collection("users").doc(this.$props.Id)
    };
  },
  watch: {
    allCustomers: {
      immediate: true,
      handler(allCustomers) {
        if (allCustomers.length === 0) {
          return;
        }

        this.customersSelectList = allCustomers.map(m => {
          return {
            text: m.CustomerName,
            value: m.Id
          };
        });
      }
    }
  },
  //   mounted() {
  //     this.isLoading = true;

  //     let self = this;
  //     db.collection("users")
  //       .doc(this.$props.Id)
  //       .get()
  //       .then(u => {
  //         self.user = u.data();

  //         this.isLoading = false;
  //       })
  //       .catch(err => {
  //         this.isLoading = false;
  //         this.$helpers.dispatch("showError", {
  //           msg: "Unexpected error occurred",
  //           vm: this
  //         });
  //       });
  //   },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;

      db.collection("users")
        .doc(this.$props.Id)
        .set(this.user, { merge: true })
        .then(() => {
          this.$router.go(-1);
          this.$helpers.dispatch("showSuccess", {
            msg: "Client updated successfully",
            vm: this
          });
        })
        .catch(err => {
          this.isLoading = false;
          this.$helpers.dispatch("showError", {
            msg: err.message ?? err,
            vm: this
          });
        });
    },
    changeSelect({ data, text }) {
      if (data.length === 0) {
        this.user.Role = "";
      }

      this.user.Role = data[0].text;

      //   if (this.user.Role !== "Customer") {
      //     this.user.AssignedToCustomerId = "";
      //     this.user.AssignedToCustomerName = "";
      //   } else {
      //     this.user.AssignedToCustomerId = this.customersSelectList[0].value;
      //     this.user.AssignedToCustomerName = this.customersSelectList[0].text;
      //   }
    },
    changeCustomerSelect({ data, text }) {
      if (data.length === 0) {
        this.user.AssignedToCustomerId = "";
        this.user.AssignedToCustomerName = "";
      }

      this.user.AssignedToCustomerId = data[0].value;
      this.user.AssignedToCustomerName = data[0].text;
    }
  }
};
</script>
