<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Add Customer" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Customer Name" v-model="customer.CustomerName" required></fg-input>
            </div>
          </div>
          <div class="row" v-if="showAgent">
            <div class="col">
              <label class="control-label">Agent</label>
              <select-picker
                emptyText="No results"
                :dropdown-data="agentsSelectList"
                placeholder="None"
                v-model="customer.AssignedToAgentName"
                @change="agentChanged"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Description" v-model="customer.Description"></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Address" v-model="customer.Address"></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Contact person" v-model="customer.Person"></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Phone number" v-model="customer.PhoneNumber"></fg-input>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col ml-1">
              <input
                id="copyCustomer"
                type="checkbox"
                v-model="copyCustomer"
                checked="this.copyCustomer"
              />
              <label for="copyCustomer">
                &nbsp;Copy asset types and questions from an existing
                customer:
              </label>
            </div>
          </div>
          <div class="row" v-if="copyCustomer">
            <div class="col">
              <select-picker
                :dropdown-data="customers"
                placeholder="None"
                v-model="this.copyCustomerName"
                @change="changeCustomerSelect"
              />
            </div>
          </div>
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3">
              <p-button
                type="danger"
                round
                @click="cancel"
                class="mr-3"
                fa="fal fa-times-octagon"
              >Cancel</p-button>
              <p-button type="info" round fa="fal fa-save" nativeType="submit">Save</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/router/store.js";
import SelectPicker from "vue-bootstrap-selectpicker";
import utils from "../../utils.js";
import { db, getCustomer } from "@/db";

export default {
  components: {
    Loading
  },
  props: {
    Id: {
      type: String,
      default: ""
    },
    Id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showAgent: false,
      copyCustomer: false,
      copyCustomerId: "",
      copyCustomerName: "",
      isLoading: false,
      allAgents: [],
      agentsSelectList: [],
      allCustomers: [],
      customers: [],
      customer: {
        Address: "",
        Description: "",
        CustomerName: "",
        Person: "",
        Id: "",
        PhoneNumber: ""
      }
    };
  },
  mounted() {
    this.customer.Id = utils.newId();
    this.showAgent = store.getters.loggedInUser.AssignedToAgentId === null;

    if (this.showAgent) {
    } else {
      this.customer.AssignedToAgentId =
        store.getters.loggedInUser.AssignedToAgentId;
      this.customer.AssignedToAgentName =
        store.getters.loggedInUser.AssignedToAgentName;
    }
  },
  firestore() {
    var user = store.getters.loggedInUser;
    var custs = null;

    if (store.getters.userIsAgentAdmin) {
      custs = db
        .collection("customers")
        .where("AssignedToAgentId", "==", user.AssignedToAgentId)
        .orderBy("CustomerName");
    } else if (store.getters.userIsAdmin) {
      custs = db.collection("customers").orderBy("CustomerName");
    }

    return {
      allCustomers: custs,
      allAgents: db.collection("agents")
    };
  },
  watch: {
    allCustomers: {
      immediate: true,
      handler(allCustomers) {
        if (allCustomers.length > 0) {
          this.customers = allCustomers.map(cust => {
            return {
              text: cust.CustomerName,
              value: cust.Id
            };
          });

          this.copyCustomerId = this.customers[0].value;
          this.copyCustomerName = this.customers[0].text;
        }
      }
    },
    allAgents: {
      immediate: true,
      handler(allAgents) {
        if (allAgents.length > 0) {
          this.agentsSelectList = allAgents.map(agent => {
            return {
              value: agent.Id,
              text: agent.Name
            };
          });

          if (store.getters.userIsAdmin) {
            this.customer.AssignedToAgentName = allAgents[0].Name;
            this.customer.AssignedToAgentId = allAgents[0].Id;
          }
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;

      db.collection("customers")
        .doc(this.customer.Id)
        .set(this.customer, { merge: true })
        .then(() => {
          if (this.copyCustomer) {
            this.copyQuestionsAndTypes();
          }

          this.$router.go(-1);
          this.$helpers.dispatch("showSuccess", {
            vm: this,
            msg: "Customer created successfully"
          });
        })
        .catch(err => {
          this.isLoading = false;
          this.$helpers.dispatch("showError2", {
            vm: this,
            err: err
          });
        });
    },
    changeCustomerSelect({ data, text }) {
      if (data.length === 0) {
        this.copyCustomerId = "";
        this.copyCustomerName = "";
      }

      this.copyCustomerId = data[0].value;
      this.copyCustomerName = data[0].text;
    },
    copyQuestionsAndTypes() {
      db.collection("customers")
        .doc(this.copyCustomerId)
        .collection("assettypes")
        .get()
        .then(snaps => {
          snaps.docs.forEach(snap => {
            const at = snap.data();
            const newAtId = utils.newId();
            const sourceId = at.Id;

            at.CustomerId = this.customer.Id;
            at.CustomerName = this.customer.CustomerName;
            at.Id = newAtId;

            //copy asset types
            db.collection("customers")
              .doc(this.customer.Id)
              .collection("assettypes")
              .doc(newAtId)
              .set(at);

            this.copyQuestions(sourceId, newAtId);
          });
        });
    },
    copyQuestions(sourceId, newAtId) {
      db.collection("customers")
        .doc(this.copyCustomerId)
        .collection("questions")
        .where("AssetTypeId", "==", sourceId)
        .get()
        .then(snaps => {
          snaps.docs.forEach(snap => {
            const q = snap.data();
            const newQId = utils.newId();

            q.AssetTypeId = newAtId;
            q.CustomerId = this.customer.Id;
            q.CustomerName = this.customer.CustomerName;
            q.Id = newQId;

            db.collection("customers")
              .doc(this.customer.Id)
              .collection("questions")
              .doc(newQId)
              .set(q);
          });
        });
    },
    agentChanged({ data, text }) {
      if (!data[0]) {
        this.customer.AssignedToAgentName = "";
        this.customer.AssignedToAgentId = "";
      }

      this.customer.AssignedToAgentName = data[0].text;
      this.customer.AssignedToAgentId = data[0].value;
    }
  }
};
</script>
<style scoped>
.bootstrap-select {
  width: 100% !important;
}
</style>
