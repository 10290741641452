<template>
  <div>
    <card
      title="Forgot Password"
      class="login"
      subTitle="Please fill in the form to reset your password"
      :showFilter="false"
    >
      <template slot="image">
        <img src="/logo3.png" style="width:400px;margin-bottom:20px;" v-if="this.host === 'Kgosi'" />
        <img src="/logo3_cloud.png" style="width:400px;margin-bottom:20px;" v-if="this.host === 'Cloud'" />
      </template>
      <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
      <div>
        <form @submit.prevent="doReset">
          <div class="row">
            <div class="col-md-12">
              <fg-input
                type="email"
                label="Email"
                placeholder="Email"
                v-model="user.email"
                required
              />
            </div>
          </div>
          <div class="text-center">
            <p-button type="info" round nativeType="submit">Submit</p-button>
          </div>
        </form>
      </div>
    </card>
  </div>
</template>

<script>
import router from "@/router/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import firebase from "firebase";
import { getHost } from "../env"

export default {
  data() {
    return {
      isLoading: false,
      user: {},
      host: getHost()
    };
  },
  components: {
    Loading
  },
  methods: {
    doReset() {
      let self = this;
      this.isLoading = true;

      firebase
        .auth()
        .sendPasswordResetEmail(this.user.email)
        .catch(err => {});

      this.isLoading = false;
      this.$router.push("/login").catch(err => {});
      self.$helpers.dispatch("showSuccess", {
        vm: self,
        msg:
          "If an account with that email address exists, reset instructions will be sent."
      });
    },
    doLoginAs(email, password) {
      this.user.email = email;
      this.user.password = password;
      this.doLogin();
    },
    loginAsRandy() {
      this.doLoginAs("randygov77@gmail.com", "w1zkpv");
    },
    loginAsFernando() {
      this.doLoginAs("fernando@vandlovu.co.za", "rk6g4f");
    },
    loginAsDewald() {
      this.doLoginAs("dewald.botha@angloamerican.com", "ct5e4k");
    },
    loginAsJustice() {
      this.doLoginAs("s.modika85@gmail.com", "1yrgfu");
    }
  }
};
</script>

<style>
.login {
  max-width: 450px;
  margin: 10% auto;
}
.card .card-image {
  height: auto !important;
  text-align: center;
}
</style>
