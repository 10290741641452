var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"btn",class:[
      {'btn-round': _vm.round},
      {'btn-block': _vm.block},
      {'btn-just-icon': _vm.icon},
      {[`btn-${_vm.type}`]: _vm.type && !_vm.outline},
      {[`btn-outline-${_vm.type}`]: _vm.type && _vm.outline},
      {[`btn-${_vm.size}`]: _vm.size},
      {'btn-link': _vm.simple}
    ],attrs:{"type":_vm.nativeType,"disabled":_vm.disabled || _vm.loading},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("fa",function(){return [(_vm.fa)?_c('i',{class:_vm.fa,staticStyle:{"margin-right":"5px","font-size":"14px"}}):_vm._e()]}),_vm._t("loading",function(){return [(_vm.loading)?_c('i',{staticClass:"fa fa-spinner fa-spin"}):_vm._e()]}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }