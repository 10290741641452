<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Edit Agent" :subTitle="agent.Name" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Agent Name" v-model="agent.Name" required />
            </div>
          </div>
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3">
              <p-button
                type="danger"
                round
                @click="cancel"
                fa="fal fa-times-octagon"
                class="mr-3"
              >Cancel</p-button>
              <p-button type="info" round fa="fal fa-save" nativeType="submit">Save</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { db } from "@/db";

export default {
  components: {
    Loading
  },
  props: {
    agentId: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.isLoading = true;
    let self = this;
    db.collection("agents")
      .doc(this.$props.agentId)
      .get()
      .then(u => {
        self.agent = u.data();
        self.isLoading = false;
      })
      .catch(err => {
        self.isLoading = false;
        self.$helpers.dispatch("showError2", {
          err: err,
          vm: self
        });
      });
  },
  data() {
    return {
      isLoading: false,
      agent: {}
    };
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;
      let self = this;

      db.collection("agents")
        .doc(this.$props.agentId)
        .set(this.agent, { merge: true })
        .then(() => {
          self.$router.go(-1);
          self.$helpers.dispatch("showSuccess", {
            msg: "Agent updated successfully",
            vm: self
          });
        })
        .catch(err => {
          self.isLoading = false;
          self.$helpers.dispatch("showError", {
            msg: err.message ?? err,
            vm: self
          });
        });
    }
  }
};
</script>
