<script>
import Axios from 'axios';
import buildQuery from 'odata-query';

export default {
    getPaged(params) {
        if (params.expand) {
            params.expand = `&$expand=${params.expand}`;
        } else {
            params.expand = '';
        }
        const top = params.perPage || 100;
        const skip = (params.perPage * (params.page - 1)) || 0;

        var sel = "";

        if (params.select && params.select !== "")
            sel = `&$select=${params.select.join(",")}`;

        if (params.filterFields) {

            var q = buildQuery({
                top,
                skip
            });

            var f = "";

            if (params.filterValue && params.filterValue !== "")
                f = "&$filter=" + params.filterFields.map(f => {
                    return `substringof('${params.filterValue}', ${f})`
                }).join(' or ');

            return Axios.get(`/tables/${params.entity}${params.path}${q}${f}&$inlinecount=allpages&$orderby=${params.orderby}${params.expand}${sel}`);
        }

        return Axios.get(`/tables/${params.entity}${params.path}?$top=${top}&$skip=${skip}&$orderby=${params.orderby}&$inlinecount=allpages${params.expand}${sel}`);
    },
    getById(entity, id, limitToFields) {
        if (limitToFields)
            return Axios.get(`/tables/${entity}/${id}?$select=${limitToFields.join(",")}`);

        return Axios.get(`/tables/${entity}/${id}`);
    },
    getAreaByIdWithSites(id) {
        return Axios.get(`/tables/area/withsites/${id}`);
    },
    updateById(entity, id, data) {
        return Axios.patch(`/tables/${entity}/${id}`, data);
    },
    create(entity, data) {
        return Axios.post(`/tables/${entity}`, data);
    },
    deleteById(entity, id) {
        return Axios.delete(`/tables/${entity}/${id}`);
    },
    getAll(entity) {
        return Axios.get(`/tables/${entity}`);
    },
    getAllOrdered(entity, orderBy) {
        var q = buildQuery({
            orderBy
        });
        return Axios.get(`/tables/${entity}${q}`);
    }
};
</script>
