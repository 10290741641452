<template>
  <div class="entity-table-container" id="divEntityTable">
    <table class="table" :class="tableClass">
      <thead>
        <th style="width:55px" v-if="sorting"></th>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{column}}</th>
          <th v-if="hasActions" class="thAction">Actions</th>
        </slot>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index" :class="item.Id">
          <td v-if="sorting">
            <div>
              <div class="mr-2" style="width: 12.25px; display:inline-block">
                <i
                  v-show="item.OrderNo != 1"
                  style="cursor:pointer"
                  class="fal fa-arrow-alt-up"
                  @click="$emit('sortUp', item.OrderNo)"
                ></i>
              </div>
              <div style="width: 12.25px; display:inline-block">
                <i
                  v-show="item.OrderNo != totalItems"
                  style="cursor:pointer"
                  class="fal fa-arrow-alt-down"
                  @click="$emit('sortDown', item.OrderNo)"
                ></i>
              </div>
            </div>
          </td>
          <slot :row="item">
            <td
              v-for="(column, index) in columns"
              :key="index"
              v-if="hasValue(item, column)"
              v-html="itemValue(item, column, index)"
            ></td>
            <td v-if="hasActions" class="tdAction">
              <p-button
                type="warning"
                class="btn-action table-action-button"
                round
                @click="$emit('editClick', item.Id)"
                v-show="actions.edit"
              >Edit</p-button>
              <p-button
                type="info"
                v-show="customAction1"
                class="btn-action table-action-button"
                round
                @click="customAction1Clicked(item.Id, item.Name)"
              >{{customAction1}}</p-button>
              <p-button
                type="danger"
                class="btn-action table-action-button"
                round
                @click="deleteClicked(item.Id, item.Name)"
                v-show="actions.delete"
              >Delete</p-button>
              
            </td>
          </slot>
        </tr>
        <tr v-if="data.length == 0 && !loading">
          <td :colspan="columns.length + 1">No records found</td>
        </tr>
        <tr v-if="loading">
          <td :colspan="columns.length + 1">Loading data, please wait...</td>
        </tr>
      </tbody>
    </table>
    <div class="row" style="align-items:center">
      <div class="col-md align-middle">
        <p-button
          v-show="showAdd"
          type="warning"
          class="btn-action table-action-button"
          round
          @click="$emit('addClick')"
        >Add new {{entityName}}</p-button>
      </div>
      <div class="pager-container col-md-auto" v-show="showPaging">
        <div
          is="uib-pagination"
          :boundary-links="true"
          :total-items="totalItems"
          @change="changePage"
          v-model="pagination"
          class="pagination-sm"
          previous-text="‹"
          next-text="›"
          first-text="«"
          last-text="»"
          :max-size="7"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import pagination from "vuejs-uib-pagination";

Vue.use(pagination);

export default {
  name: "paper-table",
  props: {
    columns: Array,
    loading: Boolean,
    data: Array,
    hasActions: {
      type: Boolean,
      default: false
    },
    sorting: {
      type: Boolean,
      default: false
    },
    showPaging: {
      type: Boolean,
      default: true
    },
    actions: Object,
    type: {
      type: String, // striped | hover
      default: "striped"
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    totalItems: {
      type: Number,
      default: 50
    },
    entityName: {
      type: String,
      default: ""
    },
    convertColumnNoToProgressBar: {
      type: Number,
      default: null
    },
    customAction1: {
      type: String,
      default: null
    },
    customAction2: {
      type: String,
      default: null
    }
  },
  computed: {
    tableClass() {
      return `table-${this.type} entity-table`;
    },
    showAdd() {
      return this.entityName !== "";
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.replace(/ /g, "")] !== "undefined";
    },
    itemValue(item, column, index) {
      if(this.convertColumnNoToProgressBar && this.convertColumnNoToProgressBar === index) {
        var percent = Math.trunc(item.Progress / item.BaseProgress * 100);
        let isNaN = (maybeNaN) => maybeNaN!=maybeNaN;
        if(isNaN(percent))
          percent = 0;

        return `<div class="progress" style="height: 20px;margin-top:5px; margin-bottom:5px;width:70%"><div class="progress-bar" style="background-color: #7A9E9F; width: ${percent}%" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div></div><div style="width:70%; text-align:center; font-size:11px;margin-bottom:5px;">${percent}% (${item.Progress} of ${item.BaseProgress})</div>`;

      }

      return item[column.replace(/ /g, "")];
    },
    changePage() {
      this.$emit("changePage", this.$data.pagination.currentPage);
    },
    deleteClicked(id, name) {
      this.$emit("deleteClick", id, name);
    },
    customAction1Clicked(id, name) {
      this.$emit("customAction1Clicked", id, name);
    },
    customAction2Clicked(id, name) {
      this.$emit("customAction2Clicked", id, name);
    }
  },
  data() {
    return {
      pagination: {
        currentPage: 1
      }
    };
  },
  components: {}
};
</script>

<style>
.button-row {
  margin: 0 30px 0 30px;
}

.entity-table-container {
  padding: 0 20px 0 20px;
}

.container {
  padding-top: 10px;
}

.pagination {
  display: flex;
}

.table-action-button {
  min-width: 90px;
  margin: 0 5px;
}
</style>

<style lang="scss">
$table-line-color: #ccc5b9 !default;
$muted-color: #a49e93 !default;
$medium-pale-bg: #f1eae0 !default;
$dark-gray: #9a9a9a !default;
$black-hr: #444444 !default;

.pager-container .pagination > li > a:focus,
.pager-container .pagination > li > a:hover,
.pager-container .pagination > li > span:focus,
.pager-container .pagination > li > span:hover {
  z-index: 3;
  color: $medium-pale-bg;
  background-color: $table-line-color;
  border-color: $muted-color;
}

.pager-container .pagination > .active > a,
.pager-container .pagination > .active > a:focus,
.pager-container .pagination > .active > a:hover,
.pager-container .pagination > .active > span,
.pager-container .pagination > .active > span:focus,
.pager-container .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: $dark-gray;
  border-color: $muted-color;
}
</style>
