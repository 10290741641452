<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Add Area" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Area Name" v-model="area.Name" required />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="control-label">Site</label>
              <select-picker
                @input="onInput"
                emptyText="No results"
                :dropdown-data="dropdownData"
                placeholder="None"
                v-model="selectedSite"
                @change="changeSelect"
                search
              />
            </div>
          </div>
          <div class="row">
            <div class="col mt-4 mb-3">
              <p-button
                type="info"
                round
                fa="fal fa-save"
                class="mr-3"
                required
                nativeType="submit"
              >Save</p-button>
              <p-button type="danger" round @click="cancel" fa="fal fa-times-octagon">Cancel</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SelectPicker from "vue-bootstrap-selectpicker";

export default {
  components: {
    Loading
  },
  mounted() {
    this.isLoading = true;
    odata
      .getAll("site")
      .then(resp => {
        this.area.SiteId = resp.data[0].id;
        this.selectedSite = resp.data[0].description;

        this.dropdownData = resp.data.map(c => {
          return {
            text: c.description,
            value: c.id
          };
        });

        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        if (err.response.status === 401) return;
        this.$helpers.dispatch("showError", {
          msg: "Unexpected error occurred",
          vm: this
        });
      });
  },
  data() {
    return {
      isLoading: false,
      area: {
        Name: "",
        SiteId: ""
      },
      dropdownData: [],
      selectedSite: ""
    };
  },
  watch: {},
  methods: {
    onInput(val) {},
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;
      var data = {
        Description: this.area.Name,
        SiteId: this.area.SiteId
      };
      odata
        .create("area", data)
        .then(resp => {
          this.$router.go(-1);
          this.isLoading = false;
          this.$helpers.dispatch("showSuccess", {
            msg: "Area created successfully",
            vm: this
          });
        })
        .catch(err => {
          this.isLoading = false;
          if (err.response.status === 401) return;
          this.$helpers.dispatch("showError", {
            msg: `Unexpected error occurred:<br>${err}`,
            vm: this
          });
        });
    },
    changeSelect({ data, text }) {
      if (data.length === 0) {
        this.selectedSite = "";
        this.area.SiteId = "";
      }

      this.selectedSite = data[0].text;
      this.area.SiteId = data[0].value;
    }
  }
};
</script>
