export default {
	paginate: function(array, page_size, page_number) {
		return array.slice(
			(page_number - 1) * page_size,
			page_number * page_size
		);
	},
	newId: function() {
		var id = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
			(
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
		);

		return id.replace(/-/g, "");
	}
};
