<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-12">
      <card
        :title="title"
        :subTitle="subTitle"
        :showFilter="showFilter"
        @doFilter="doFilter"
        :filterInputValue="filterTerm"
      >
        <div slot="raw-content" class="table-responsive">
          <paper-table
            :data="displayData"
            :loading="loading"
            :columns="columns"
            :hasActions="hasActions"
            :actions="actions"
            :totalItems="totalItems"
            @changePage="changePage"
            @editClick="editClick"
            @deleteClick="deleteClick"
            @addClick="addClick"
            entityName="Area"
            customAction1="View Assets"
            @customAction1Clicked="viewAssetsClicked"
          ></paper-table>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { PaperTable } from "@/components";
import { db, getCustomer } from "@/db";
import utils from "@/utils";
import store from "@/router/store";

const tableColumns = ["Name", "Site"];

export default {
  components: {
    PaperTable,
    Loading
  },
  props: {
    siteId: {
      type: String,
      defualt: null
    }
  },
  data() {
    return {
      loading: true,
      title: "Areas",
      subTitle: `All ${store.getters.selectedCustomer.CustomerName} areas are listed below`,
      hasActions: true,
      actions: {
        edit: true,
        delete: true
      },
      columns: [...tableColumns],
      allData: [],
      displayData: [],
      filteredData: [],
      isLoading: false,
      currentPage: 1,
      totalItems: 1,
      showFilter: true,
      filterTerm: ""
    };
  },
  watch: {
    siteId: {
      immediate: true,
      handler(siteId) {
        let sites = siteId
          ? getCustomer()
              .collection("areas")
              .where("SiteId", "==", this.$props.siteId)
          : getCustomer().collection("areas");

        this.$bind("allData", sites);
      }
    },
    allData: {
      immediate: true,
      handler(allData) {
        if (allData.length > 0) {
          this.loading = false;
        }

        this.filterData();
      }
    },
    filterTerm: {
      immediate: true,
      handler(filterTerm) {
        this.filterData();
      }
    }
  },
  firestore() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);

    let sites = this.$props.siteId
      ? getCustomer()
          .collection("areas")
          .where("SiteId", "==", this.$props.siteId)
      : getCustomer().collection("areas");
    return {
      allData: sites
    };
  },
  methods: {
    loadPage(num) {
      this.isLoading = true;
      if (!num) num = this.currentPage;
      let take = 10;
      this.displayData = utils.paginate(this.filteredData, take, num);
      this.isLoading = false;
    },
    filterData() {
      var search = this.filterTerm.toUpperCase();
      var result = this.allData.filter(f => {
        return f.AreaName.toUpperCase().includes(search);
      });

      this.filteredData = result
        .sort(function(a, b) {
          return a.AreaName.toUpperCase() > b.AreaName.toUpperCase() ? 1 : -1;
        })
        .map(c => {
          return {
            Name: c.AreaName,
            Id: c.Id,
            Site: c.SiteName
          };
        });

      this.totalItems = this.filteredData.length;
      this.loadPage(1);
    },
    changePage(pageNum) {
      this.currentPage = pageNum;
      this.loadPage(pageNum);
    },
    doFilter(filter) {
      this.filterTerm = filter;
    },
    editClick(areaId) {
      this.$router.push(`/editarea/${areaId}`);
    },
    addClick(id) {
      this.$router.push(`/addarea/${this.$props.siteId ?? ""}`);
    },
    viewAssetsClicked(id) {
      this.$router.push(`/assets/${id}`);
    },
    deleteClick(id, description) {
      if (
        window.confirm(
          `Are you sure you want to delete the area '${description}'?`
        )
      ) {
        this.isLoading = true;
        let self = this;
        this.isLoading = true;
        getCustomer()
          .collection("areas")
          .doc(id)
          .delete()
          .then(() => {
            self.$helpers.dispatch("showSuccess", {
              msg: "Area deleted successfully",
              vm: self
            });
          })
          .catch(err => {
            self.isLoading = false;
            self.$helpers.dispatch("showError2", {
              vm: self,
              err: err
            });
          });
      }
    }
  }
};
</script>
