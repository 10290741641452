<template>
<div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <router-view></router-view>
</div>
</template>

<script>
const $j = require('jquery');
window.$j = $j;
export default {

};
</script>

<style>
.type-icon {
	text-align: center;
}
</style>

<style lang="scss">
.alert span[data-notify="icon"] {
    top: 65% !important;
}

.vue-notifyjs .alert .close~span {
    text-align: center;
}

.vue-notifyjs .alert .alert-icon {
  font-size: 25px !important;
}

.vue-notifyjs.notifications {
    .alert {
        z-index: 10000;
    }

    .list-move {
        transition: transform 0.3s, opacity 0.4s;
    }

    .list-item {
        display: inline-block;
        margin-right: 10px;
    }

    .list-enter-active {
        transition: transform 0.2s ease-in, opacity 0.4s ease-in;
    }

    .list-leave-active {
        transition: transform 1s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
        opacity: 0;
        transform: scale(1.1);
    }

    .list-leave-to {
        opacity: 0;
        transform: scale(1.2, 0.7);
    }
}
</style>
