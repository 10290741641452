<template>
  <div>
    <card
      title="Dashboard Login"
      class="login"
      subTitle="Please fill in the form to login"
      :showFilter="false"
    >
      <template slot="image">
        <img src="/logo3.png" style="width:400px;margin-bottom:20px;" v-if="this.host === 'Kgosi'" />
        <img src="/logo3_cloud.png" style="width:400px;margin-bottom:20px;" v-if="this.host === 'Cloud'" />

      </template>
      <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
      <div>
        <form @submit.prevent="doLogin">
          <div class="row">
            <div class="col-md-12">
              <fg-input
                type="email"
                label="Email"
                placeholder="Email"
                v-model="user.email"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input
                type="password"
                label="Password"
                placeholder="Password"
                v-model="user.password"
              />
            </div>
          </div>
          <div class="text-center">
            <p-button type="info" round nativeType="submit">Login</p-button>
          </div>
          <div class="text-center mt-4">
            <router-link style="color:#F38E24" to="/forgotpassword">Reset Password</router-link>
          </div>
        </form>
      </div>
    </card>
  </div>
</template>

<script>
import router from "@/router/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Axios from "axios";
import { getHost } from "../env"

export default {
  data() {
    return {
      isLoading: false,
      user: {},
      auth: this.$route.params.auth,
      host: getHost()
    };
  },
  components: {
    Loading
  },
  methods: {
    doLogin() {
      this.$helpers.dispatch("clearAllAlerts", {
        vm: this
      });

      this.isLoading = true;
      let email = this.user.email;
      let password = this.user.password;

      this.$store
        .dispatch("login", {
          email,
          password,
          vm: this
        })
        .then((a, b, c) => {
          this.isLoading = false;
        })
        .catch(err => {
          this.$helpers.dispatch("showError2", {
            err: err,
            vm: this
          });
          this.isLoading = false;
        });
    },
    doLoginAs(email, password) {
      this.user.email = email;
      this.user.password = password;
      this.doLogin();
    }
  }
};
</script>

<style scoped>
.login {
  max-width: 450px;
  margin: 10% auto;
}
.card .card-image {
  height: auto !important;
  text-align: center;
}
body {
  margin-top: 60px;
}

#sacoronavirus-banner {
  position: fixed;
  height: 60px;
  background-color: #fe9d00;
  left: 0;
  right: 0;
  top: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  z-index: 99;
  font-size: 16px;
}

#sacoronavirus-banner #sacoronavirus-content a {
  color: white;
  text-decoration: none;
}

#sacoronavirus-banner #sacoronavirus-content {
  height: 60px;
  width: 600px;
}

#sacoronavirus-banner #sacoronavirus-content p {
  margin: 8px auto;
  float: left;
}

#sacoronavirus-banner #sacoronavirus-content img {
  width: 275px;
  height: 60px;
  float: right;
}
</style>
