<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-12">
      <card
        :title="title"
        :subTitle="subTitle"
        :showFilter="showFilter"
        @doFilter="doFilter"
        :filterInputValue="filterTerm"
      >
        <div slot="raw-content" class="table-responsive">
          <paper-table
            :data="data"
            :columns="columns"
            :hasActions="hasActions"
            :actions="actions"
            :totalItems="totalItems"
            @changePage="changePage"
          />
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import odata from "@/services/odata.vue";
import moment from "moment";
import { PaperTable } from "@/components";

const tableColumns = ["Asset", "Date", "User", "Action"];

export default {
  components: {
    PaperTable,
    Loading
  },
  props: {},
  watch: {},
  data() {
    return {
      title: "Services",
      subTitle: `All ${store.getters.selectedCustomer.CustomerName} services are listed below`,
      actions: {},
      hasActions: false,
      columns: [...tableColumns],
      data: [],
      isLoading: false,
      currentPage: 1,
      totalItems: 1,
      showFilter: true,
      filterTerm: ""
    };
  },
  mounted() {
    this.loadPage(1);
  },
  methods: {
    changePage(pageNum) {
      this.currentPage = pageNum;
      this.loadPage(pageNum);
    },
    doFilter(filter) {
      this.filterTerm = filter;
      this.loadPage(1);
    },
    refreshData() {
      this.loadPage();
    },
    hideLoadingAtNextTick() {
      Vue.nextTick(() => {
        this.isLoading = false;
      });
    },
    getTypeHtml(type, tag) {
      switch (type) {
        case "Fire Panel":
            return `<i class="type-icon fal fa-light fa-panel-fire" title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "TUNNEL SPRINKLER BST":
          return `<i class='type-icon fab fa-empire' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Suppression System":
          return `<i class='type-icon fal fa-cloud-meatball' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Door":
          return `<i class='type-icon fal fa-door-open' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Kolomela Fire Hydrant":
          return `<i class='type-icon fal fa-tint' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Other":
          return `<i class='type-icon fal fa-map-marker-question' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Detection System":
          return `<i class='type-icon fal fa-thermometer-three-quarters' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Hose":
          return `<i class='type-icon fal fa-tape' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Hydrant":
          return `<i class='type-icon fal fa-tint' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Hose Reel":
          return `<i class='type-icon fal fa-tape' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Extinguisher":
          return `<i class='type-icon fal fa-fire-extinguisher' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "First Aid Box":
          return `<i class='type-icon fal fa-medkit' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Vehicle Fire Suppression":
          return `<i class='type-icon fal fa-truck-monster' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Diesel Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Water Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
		case "Electric Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
		case "Jockey Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        default:
          return `<i class='type-icon fal fa-map-marker-question' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
      }
    },
    loadPage(num) {
      if (!num) num = this.currentPage;

      this.isLoading = true;
      let params = {
        entity: "assetservice",
        path: "",
        perPage: 10,
        page: num,
        orderby: "ActionDate desc",
        filterFields: ["AssetId", "ServiceAction", "Username"],
        filterValue: this.filterTerm
        //select: ['Id', 'ServiceAction', 'Username', 'ActionDate']
        //expand: 'User'
      };
      odata
        .getPaged(params)
        .then(resp => {
          this.totalItems = resp.data.count;
          this.data = resp.data.results.map(val => {
            return {
              asset: this.getTypeHtml(val.assetType, val.tagNo),
              id: val.id,
              tagNo: val.tagNo,
              action: val.serviceActionString,
              user: val.username,
              date: moment(val.actionDate).format("D-MMM-YYYY HH:mm")
            };
          });
          this.hideLoadingAtNextTick();
        })
        .catch(err => {
          this.hideLoadingAtNextTick();
          if (err.response.status === 401) return;
          this.$helpers.dispatch("showError", {
            msg: "Unexpected error occurred",
            vm: this
          });
        });
    }
  }
};
</script>

<style>
</style>
