<template>
  <div class="wrapper">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <side-bar>
      <template slot="links">
        <sidebar-link to="/home" name="Home" icon="fal fa-home-lg" />
        <sidebar-link
          to="/agents"
          name="Agents"
          icon="fal fa-hands-helping"
          v-show="isInAnyRole(['Root', 'Admin'])"
        />
        <sidebar-link
          to="/auditors"
          name="Auditors"
          icon="fal fa-user-hard-hat"
          v-show="isInAnyRole(['Root', 'Admin', 'Agent Admin'])"
        />
        <sidebar-link
          to="/customers"
          name="Customers"
          icon="fal fa-briefcase"
          v-show="isInAnyRole(['Root', 'Admin', 'Agent Admin'])"
        />
        <sidebar-link
          to="/clients"
          name="Clients"
          icon="fal fa-user-tie"
          v-show="isInAnyRole(['Root', 'Admin', 'Agent Admin'])"
        />
        <sidebar-link
          to="/assettypes"
          name="Asset Types"
          icon="fal fa-fire-extinguisher"
          v-show="isInAnyRole(['Root', 'Admin', 'Agent Admin'])"
        />
        <!-- <sidebar-link
          to="/questions"
          name="Questions"
          icon="fal fa-question-square"
          v-show="!isInRole('Customer')"
        />-->
        <sidebar-link
          to="/sites"
          name="Sites"
          icon="fal fa-building"
          v-show="isInAnyRole(['Root', 'Admin', 'Agent Admin'])"
        />
        <!-- <sidebar-link
          to="/areas"
          name="Areas"
          icon="fal fa-map-marker-alt"
          v-show="!isInRole('Customer')"
        />-->
        <!-- <sidebar-link
          to="/assets"
          name="Assets"
          icon="fal fa-barcode-read"
          v-show="!isInRole('Customer')"
        />-->
        <!-- <sidebar-link
          to="/services"
          name="Service"
          icon="fal fa-tools"
          v-show="!isInRole('Customer')"
        />-->
        <!-- <sidebar-link
          to="/audits"
          name="Audits"
          icon="fal fa-tasks"
          v-show="!isInRole('Customer')"
        />-->
        <sidebar-link to="/reports" name="Reports" icon="fal fa-chart-line" />
        <sidebar-link to="/settings" name="Settings" icon="fal fa-sliders-v-square" />
        <!-- <sidebar-link to="/dashboard" name="Dashboard" icon="ti-panel" />
            <sidebar-link to="/stats" name="User Profile" icon="ti-user" />
            <sidebar-link to="/table-list" name="Table List" icon="ti-view-list-alt" />
            <sidebar-link to="/typography" name="Typography" icon="ti-text" />
            <sidebar-link to="/icons" name="Icons" icon="ti-pencil-alt2" />
            <sidebar-link to="/maps" name="Map" icon="ti-map" />
        <sidebar-link to="/notifications" name="Notifications" icon="ti-bell" />-->
      </template>
      <mobile-menu>
        <li class="nav-item">
          <a class="nav-link">
            <i class="ti-panel"></i>
            <p>Stats</p>
          </a>
        </li>
        <drop-down class="nav-item" title="5 Notifications" title-classes="nav-link" icon="ti-bell">
          <a class="dropdown-item">Notification 1</a>
          <a class="dropdown-item">Notification 2</a>
          <a class="dropdown-item">Notification 3</a>
          <a class="dropdown-item">Notification 4</a>
          <a class="dropdown-item">Another notification</a>
        </drop-down>
        <li class="nav-item">
          <a class="nav-link">
            <i class="ti-settings"></i>
            <p>Settings</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link">
            <i class="fal fa-sign-out-alt"></i>
            <p>Logout</p>
          </a>
        </li>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <div class="main-panel vld-parent" ref="mainPanel">
      <top-navbar v-on:showLoading="changeLoading"></top-navbar>

      <dashboard-content @click.native="toggleSidebar" />

      <content-footer></content-footer>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import odata from "@/services/odata.vue";
import Axios from "axios";
import store from "@/router/store.js";
import Vue from "vue";
import Loading from "vue-loading-overlay";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    Loading
  },
  data() {
    return {
      isLoading: true
    };
  },
  methods: {
    changeLoading(val) {
      this.isLoading = false;
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isInRole(role) {
      return store.getters.userRole === role;
    },
    isInAnyRole(roles) {
      var returnVal = false;
      roles.forEach(r => {
        if (this.isInRole(r)) returnVal = true;
      });
      return returnVal;
    }
  },
  props: {}
};
</script>
