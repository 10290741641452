import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {
		showError({ commit }, { vm, msg }) {
			var customMessage = Vue.component("custom-message", {
				template: "<span>" + msg + "</span>"
			});
			vm.$notify({
				component: customMessage,
				icon: "far fa-exclamation-triangle",
				horizontalAlign: "center",
				verticalAlign: "top",
				type: "danger",
				timeout: 3000
			});
		},
		showError2({ commit }, { vm, err }) {
			var msg =
				err.message ?? typeof err === "string"
					? err
					: "An unexpected error has occurred, please contact the system administrator";
			var customMessage = Vue.component("custom-message", {
				template: "<span>" + msg + "</span>"
			});
			vm.$notify({
				component: customMessage,
				icon: "far fa-exclamation-triangle",
				horizontalAlign: "center",
				verticalAlign: "top",
				type: "danger",
				timeout: 3000
			});
		},
		showSuccess({ commit }, { vm, msg }) {
			var customMessage = Vue.component("custom-message", {
				template: "<span>" + msg + "</span>"
			});
			vm.$notify({
				component: customMessage,
				icon: "fal fa-thumbs-up",
				horizontalAlign: "center",
				verticalAlign: "top",
				type: "success",
				timeout: 3000
			});
		},
		clearAllAlerts({ commit }, { vm }) {
			return new Promise(resolve => {
				vm.$notifications.clear();
				resolve();
			});
		}
	},
	getters: {}
});
