<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Edit Area" :subTitle="area.AreaName" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Area Name" v-model="area.AreaName" required></fg-input>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col">
              <label class="control-label">Site</label>
              <select-picker
                emptyText="No results"
                :dropdown-data="sitesSelectList"
                placeholder="None"
                v-model="area.SiteName"
                @change="siteChanged"
                required
                search
              />
            </div>
          </div>-->
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3">
              <p-button
                type="danger"
                round
                @click="cancel"
                class="mr-3"
                fa="fal fa-times-octagon"
              >Cancel</p-button>
              <p-button type="info" nativeType="submit" round fa="fal fa-save">Save</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SelectPicker from "vue-bootstrap-selectpicker";
import { db, getCustomer } from "@/db";
import utils from "@/utils";
import store from "@/router/store";

export default {
  components: {
    Loading
  },
  props: {
    areaId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isLoading: false,
      area: {},
      //allSites: [],
      //sitesSelectList: []
    };
  },
  firestore() {
    return {
      // allSites: getCustomer()
      //   .collection("sites")
      //   .orderBy("SiteName"),
      area: getCustomer()
        .collection("areas")
        .doc(this.$props.areaId)
    };
  },
  // watch: {
  //   allSites: {
  //     immediate: true,
  //     handler(allSites) {
  //       this.sitesSelectList = allSites.map(site => {
  //         return {
  //           value: site.Id,
  //           text: site.SiteName
  //         };
  //       });
  //     }
  //   }
  // },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;
      let self = this;
      getCustomer()
        .collection("areas")
        .doc(this.$props.areaId)
        .set(this.area, { merge: true })
        .then(() => {
          self.$router.go(-1);
          self.$helpers.dispatch("showSuccess", {
            msg: "Area updated successfully",
            vm: self
          });
        })
        .catch(err => {
          self.isLoading = false;
          self.$helpers.dispatch("showError", {
            msg: err.message ?? err,
            vm: self
          });
        });
    },
    // siteChanged({ data, text }) {
    //   if (!data[0]) {
    //     this.area.SiteId = "";
    //     this.area.SiteName = "";
    //   }

    //   this.area.SiteName = data[0].text;
    //   this.area.SiteId = data[0].value;
    // }
  }
};
</script>
