<template>
  <div class="ml-2">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="row">
      <div class="mt-3 pl-5 col-lg-2 col-md-4 col-sm-12">Site</div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <multiselect
          v-model="selectedSite"
          :options="siteList"
          placeholder="Search sites (or leave blank for All)"
          :showLabels="false"
          label="text"
          @search-change="searchSites"
          :loading="sitesLoading"
          track-by="id"
          :searchable="true"
          :internal-search="false"
          :close-on-select="true"
          @remove="siteUnselected"
          @select="siteSelected"
        >
          <span slot="noResult">No results</span>
          <span slot="noOptions">Start typing to search</span>
        </multiselect>
      </div>
    </div>
    <div class="row mt-3" v-show="areaSearchVisible">
      <div class="mt-3 pl-5 col-lg-2 col-md-4 col-sm-12">Area</div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <multiselect
          v-model="selectedArea"
          :options="areaList"
          :placeholder="areaPlaceholder"
          :showLabels="false"
          label="AreaName"
          @search-change="searchAreas"
          :loading="areasLoading"
          track-by="id"
          :searchable="true"
          :internal-search="false"
          :close-on-select="true"
        >
          <span slot="noResult">No results</span>
          <span slot="noOptions">Start typing to search</span>
        </multiselect>
      </div>
    </div>
    <div class="row mt-3">
      <div class="mt-3 pl-5 col-lg-2 col-md-4 col-sm-12">Dates</div>
      <div class="col-lg-3 col-md-6 col-sm-12 datepicker-trigger">
        <input
          type="text"
          id="dates-trigger"
          placeholder="Select dates"
          :value="formatDates(startDate, endDate)"
        />
        <AirbnbStyleDatepicker
          :trigger-element-id="'dates-trigger'"
          :mode="'range'"
          :showShortcutsMenuTrigger="false"
          :fullscreen-mobile="true"
          :date-one="startDate"
          :date-two="endDate"
          @date-one-selected="val => { startDate = val }"
          @date-two-selected="val => { endDate  = val }"
        />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-5 col-md-10 col-sm-12 text-right">
        <button class="btn btn-action btn-round btn-primary" @click="viewReport">View</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import moment from "moment";
import format from "date-fns/format";
import Loading from "vue-loading-overlay";
import store from "@/router/store";
import jsreport from "jsreport-browser-client-dist";
import { db, getCustomer } from "@/db";
import { getHost } from "../../env"

Vue.component("multiselect", Multiselect);

const selectDateFormat = "D MMM";
const longDateFormat = "D MMM YYYY";

export default {
  components: {
    Multiselect,
    Loading
  },
  data() {
    return {
      env: getHost(),
      isLoading: false,
      allSites: [],
      siteList: [],
      areaList: [],
      selectedSite: null,
      selectedArea: null,
      siteSearchTerm: "",
      startDate: moment()
        .startOf("month")
        .toDate(),
      endDate: moment()
        .endOf("day")
        .toDate(),
      prettyDate: "",
      sitesLoading: false,
      areasLoading: false,
      dateFormat: selectDateFormat,
      longDateFormat: longDateFormat,
      areaSearchVisible: false
    };
  },
  watch: {
    allSites: {
      immediate: true,
      handler(allSites) {
        if (allSites.length > 0) {
          this.filterSites();
        }
      }
    },
    siteSearchTerm: {
      immediate: true,
      handler(searchTerm) {
        this.filterSites();
      }
    },
    selectedSite: {
      immediate: false,
      handler(site) {
        if (site) {
          var areas = getCustomer()
            .collection("areas")
            .where("SiteId", "==", site.value);

          this.$bind("areaList", areas);
        }
      }
    }
  },
  firestore() {
    return {
      allSites: getCustomer().collection("sites")
    };
  },
  computed: {
    areaPlaceholder: function () {
      return this.selectedSite
        ? "Search area (or leave blank for All)"
        : "Search sites then areas";
    }
  },
  methods: {
    formatDates(dateOne, dateTwo) {
      var retVal = "";

      if (dateOne && dateTwo) {
        retVal = `${format(dateOne, this.dateFormat)} - ${format(
          dateTwo,
          this.dateFormat
        )}`;
        this.prettyDate = `${format(dateOne, this.longDateFormat)} - ${format(
          dateTwo,
          this.longDateFormat
        )}`;
      }

      return retVal;
    },
    searchSites(q) {
      this.siteSearchTerm = q;
    },
    filterSites() {
      var search = this.siteSearchTerm.toUpperCase();

      this.selectedArea = null;
      this.areaSearchVisible = false;

      var result = this.allSites.filter(f => {
        return search === "" || f.SiteName.toUpperCase().includes(search);
      });

      this.siteList = result.map(site => {
        return {
          text: site.SiteName,
          value: site.Id
        };
      });
    },
    searchAreas(q) {
      //   this.isAreasLoading = true;
      //   let siteId = this.selectedSite.id;
      //   axios.get(`/api/areas/search/${siteId}/${q}`).then(response => {
      //     this.areaList = response.data;
      //     this.isAreasLoading = false;
      //   });
    },
    siteUnselected() {
      this.selectedArea = null;
      this.areaSearchVisible = false;
    },
    siteSelected() {
      this.areaSearchVisible = true;
    },
    hideLoadingAtNextTick() {
      Vue.nextTick(() => {
        this.isLoading = false;
      });
    },
    viewReport() {
      //this.isLoading = true;
      var data = {
        DateGenerated: moment().format("DD-MMM-YYYY HH:mm"),
        CustomerId: store.getters.selectedCustomer.Id,
        CustomerName: store.getters.selectedCustomer.CustomerName,
        Client: this.env,
      };

      if (this.selectedSite) {
        data.SiteId = this.selectedSite.value;
        data.Site = this.selectedSite.text;
      }

      if (this.selectedArea) {
        data.AreaId = this.selectedArea.Id;
        data.Area = this.selectedArea.AreaName;
      }

      data.StartDate = moment(this.startDate).format(
        "YYYY-MM-DD 00:00:00.0000000 +02:00"
      );
      data.EndDate = moment(this.endDate).format(
        "YYYY-MM-DD 23:59:59.0000000 +02:00"
      );
      data.PrettyDate = this.prettyDate;

     //console.log("data", data);

      var host = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
      var loader = `<html><head><link type="text/css" href="${host}/loader.css" rel="stylesheet"></head><body><div class="loading"><div class="heading">Your report is being generated</div><div class="loader"></div></div></body></html>`;
      let win = window.open("about:blank", "Failed Audit Report");
      win.document.write(loader);

      axios
        .post("https://fema-reports.calmsmoke-626cd2e8.southafricanorth.azurecontainerapps.io/reports/failedaudit", data, {
          responseType: "arraybuffer"
        })
        .then(resp => {
          var blob = new Blob([resp.data], { type: "application/pdf" });
          var blobUrl = window.URL.createObjectURL(blob);
          var html = `<html><style>html,body {padding:0;margin:0;} iframe {width:100%;height:100%;border:0}</style><body><iframe type="application/pdf" src="${blobUrl}"></iframe></body></html>`;
          win.document.getElementsByTagName("body")[0].innerHTML = "";
          win.document.write(html);
          win.document.close();
          win.onbeforeunload = function () {
            window.URL.revokeObjectURL(blobUrl);
          };
        })
        .catch(err => {
          win.close();
          this.$helpers.dispatch("showError", {
            msg: "Unexpected error occurred",
            vm: this
          });
        })
        .finally(f => {
          this.hideLoadingAtNextTick();
        });
    }
  }
};
</script>

<style scoped>
#dates-trigger {
  width: 100%;
  min-height: 40px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
