<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Add Auditor" :subTitle="subTitle" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Name" v-model="user.Name" required />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="email" label="Email" v-model="user.Email" required />
            </div>
          </div>
          <!-- <div class="row">
            <div class="col">
              <fg-input type="text" label="Username" v-model="user.UserName" required />
            </div>
          </div>-->
          <div class="row">
            <div class="col">
              <fg-input type="password" label="Password" v-model="password" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="password" label="Confirm Password" v-model="confirmPassword" />
            </div>
          </div>
          <!-- <div class="row">
            <div class="col">
              <label class="control-label">Role</label>
              <select-picker
                emptyText="No results"
                :dropdown-data="rolesSelectList"
                placeholder="None"
                v-model="user.Role"
                @change="changeSelect"
                search
              />
            </div>
          </div> -->
          <!-- <div class="row" v-if="user.Role === 'Customer'">
            <div class="col">
              <label class="control-label">Customer</label>
              <select-picker
                :dropdown-data="customersSelectList"
                placeholder="None"
                v-model="user.AssignedToCustomerName"
                @change="changeCustomerSelect"
                search
              />
            </div>
          </div>-->
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3">
              <p-button
                type="danger"
                round
                @click="cancel"
                fa="fal fa-times-octagon"
                class="mr-3"
              >Cancel</p-button>
              <p-button type="info" round fa="fal fa-save" nativeType="submit">Save</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Axios from "axios";
import SelectPicker from "vue-bootstrap-selectpicker";
import utils from "@/utils.js";
import store from "@/router/store.js";
import firebase from "firebase";
import { db, getCustomer } from "@/db";

require("firebase/firestore");

export default {
  components: {
    Loading
  },
  props: {},
  data() {
    return {
      isLoading: false,
      subTitle:
        "Add a new " + store.getters.selectedCustomer.AssignedToAgentName + " auditor login",
      password: "",
      confirmPassword: "",
      user: {
        AssignedToCustomerId: "",
        AssignedToCustomerName: "",
        AssignedToAgentId: "",
        AssignedToAgentName: "",
        Email: "",
        Id: "",
        Name: "",
        Role: "Auditor",
        uid: "",
        Password: ""
      },
      customersSelectList: [],
      allCustomers: []
    };
  },
  mounted() {
    this.user.Id = utils.newId();
    this.user.AssignedToCustomerId = store.getters.selectedCustomer.Id;
    this.user.AssignedToCustomerName =
      store.getters.selectedCustomer.CustomerName;

    if (store.getters.userIsAdmin) {
      this.user.AssignedToAgentId =
        store.getters.selectedCustomer.AssignedToAgentId;
      this.user.AssignedToAgentName =
        store.getters.selectedCustomer.AssignedToAgentName;
    } else {
      this.user.AssignedToAgentId =
        store.getters.loggedInUser.AssignedToAgentId;
      this.user.AssignedToAgentName =
        store.getters.loggedInUser.AssignedToAgentName;
    }
  },
  firestore() {
    return {
      allCustomers: db.collection("customers")
    };
  },
  watch: {
    allCustomers: {
      immediate: true,
      handler(allCustomers) {
        if (allCustomers.length === 0) {
          return;
        }

        this.customersSelectList = allCustomers.map(m => {
          return {
            text: m.CustomerName,
            value: m.Id
          };
        });
      }
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      let self = this;

      if (self.password !== self.confirmPassword) {
        self.$helpers.dispatch("showError", {
          msg: `Passwords do not match`,
          vm: self
        });
        return;
      }

      self.isLoading = true;

      self.user.Password = self.password;
      self.user.Email = self.user.Email.toLowerCase();
      self.user.Role = "Auditor";

      db.collection("newusers")
        .doc(self.user.Id)
        .set(self.user, { merge: true })
        .then(() => {
          self.$router.go(-1);
          self.$helpers.dispatch("showSuccess", {
            vm: self,
            msg: "Auditor created successfully"
          });
        })
        .catch(err => {
          self.isLoading = false;
          self.$helpers.dispatch("showError2", {
            vm: self,
            err: err
          });
        });
    }
    // changeSelect({ data, text }) {
    //   if (data.length === 0) {
    //     this.user.Role = "";
    //   }

    //   this.user.Role = data[0].text;
    // }
    // changeCustomerSelect({ data, text }) {
    //   if (data.length === 0) {
    //     this.user.AssignedToCustomerId = "";
    //     this.user.AssignedToCustomerName = "";
    //   }

    //   this.user.AssignedToCustomerId = data[0].value;
    //   this.user.AssignedToCustomerName = data[0].text;
    // }
  }
};
</script>
