<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :opacity="0.85"
      :is-full-page="true"
    ></loading>
    <div class="col-12">
      <card
        :title="title"
        :subTitle="subTitle"
        :showFilter="showFilter"
        @doFilter="doFilter"
        :filterInputValue="filterTerm"
      >
        <div slot="raw-content" class="table-responsive">
          <paper-table
            :data="displayData"
            :loading="loading"
            :columns="columns"
            :hasActions="hasActions"
            :actions="actions"
            :totalItems="totalItems"
            :convertColumnNoToProgressBar="2"
            @changePage="changePage"
            @editClick="editClick"
            @deleteClick="deleteClick"
            @addClick="addClick"
            entityName="Site"
            customAction1="View Areas"
            @customAction1Clicked="customAction1Clicked"
          ></paper-table>
        </div>
        <div class="row">
          <div class="col text-right">
            <input
              id="Archived"
              type="checkbox"
              v-model="showArchived"
              @click="showHideArchived()"
              class="pull-right mt-3 ml-3"
            />
            <label for="Archived"> &nbsp;Show Archived Sites</label>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { PaperTable } from "@/components";
import { db, getCustomer } from "@/db";
import utils from "../../utils.js";
import store from "../../router/store";
import moment from "moment";

var tableColumns = ["Name", "Asset Count", "Auditing Progress This Month"];

export default {
  components: {
    PaperTable,
    Loading,
  },
  mounted() {
    this.startOfMonth = moment.utc().startOf("month").unix();
    console.log(this.startOfMonth);
  },
  data() {
    return {
      startOfMonth: 0,
      showArchived: false,
      loading: true,
      title: "Sites",
      subTitle: `All ${store.getters.selectedCustomer.CustomerName} sites are listed below. <br>Asset Count and Auditing Progress is updated every hour on the hour.`,
      hasActions: true,
      actions: {
        edit: true,
        delete: true,
      },
      columns: [...tableColumns],
      allData: [],
      displayData: [],
      filteredData: [],
      isLoading: false,
      currentPage: 1,
      totalItems: 1,
      showFilter: true,
      filterTerm: "",

    };
  },
  watch: {
    allData: {
      immediate: true,
      handler(allData) {
        if (allData.length > 0) {
          this.loading = false;
        }

        this.filterData();
      },
    },
    filterTerm: {
      immediate: true,
      handler(filterTerm) {
        this.filterData();
      },
    },
  },
  firestore() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);

    return {
      allData: getCustomer().collection("sites"),
    };
  },
  methods: {
    showHideArchived() {
      this.showArchived = !this.showArchived;
      this.filterData();
    },
    loadPage(num) {
      this.isLoading = true;
      if (!num) num = this.currentPage;
      let take = 10;
      this.displayData = utils.paginate(this.filteredData, take, num);
      this.isLoading = false;
    },
    filterData() {
      var search = this.filterTerm.toUpperCase();
      var result = this.allData.filter((f) => {
        var archived = false;

        if (f.Archived) archived = f.Archived;

        return (
          f.SiteName.toUpperCase().includes(search) &&
          (this.showArchived === archived || this.showArchived === true)
        );
      });

      this.filteredData = result
        .sort(function (a, b) {
          return a.SiteName.toUpperCase() > b.SiteName.toUpperCase() ? 1 : -1;
        })
        .map((c) => {
          var archivedString = "";
          var assetCount = c.AssetCount;
          var progress = 0;

          if(!assetCount) {
            assetCount = 0;
          }

          if(c['AuditCount_' + this.startOfMonth])
            progress = c['AuditCount_' + this.startOfMonth];

          if (c.Archived)
            archivedString = " [Archived]";

          return {
            Name: c.SiteName + archivedString,
            AssetCount: assetCount,
            ProgressThisMonth: 8,
            Id: c.Id,
            Progress: progress,
            BaseProgress: assetCount
          };
        });

      this.totalItems = this.filteredData.length;
      this.loadPage(1);
    },
    changePage(pageNum) {
      this.currentPage = pageNum;
      this.loadPage(pageNum);
    },
    doFilter(filter) {
      this.filterTerm = filter;
      this.loadPage(1);
    },
    editClick(id) {
      this.$router.push(`/editsite/${id}`);
    },
    addClick(id) {
      this.$router.push(`/addsite`);
    },
    customAction1Clicked(id) {
      this.$router.push(`/areas/${id}`);
    },
    deleteClick(id, description) {
      if (
        window.confirm(
          `Are you sure you want to delete the site '${description}'? \n\nWARNING: THIS WILL ALSO DELETE ALL ASSOCIATED AREAS, ASSETS, AND PAST AUDITS. \n\nPROCEED WITH CAUTION.`
        )
      ) {
        this.isLoading = true;
        let self = this;
        this.isLoading = true;
        getCustomer()
          .collection("sites")
          .doc(id)
          .delete()
          .then(() => {
            self.$helpers.dispatch("showSuccess", {
              msg: "Site deleted successfully",
              vm: self,
            });
          })
          .catch((err) => {
            self.isLoading = false;
            self.$helpers.dispatch("showError2", {
              vm: self,
              err: err,
            });
          });
      }
    },
  },
};
</script>
