<template>
  <div class="ml-2">
    <div class="row">
      <div class="mt-3 pl-5 col-lg-2 col-md-4 col-sm-12">Select timeframe</div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <multiselect :options="modeList" :showLabels="false" v-model="selectedMode" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-5 col-md-10 col-sm-12 text-right">
        <button class="btn btn-action btn-round btn-primary" @click="viewReport">View</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment";
import Multiselect from "vue-multiselect";
import store from "@/router/store";
import { getHost } from "../../env"

Vue.component("multiselect", Multiselect);

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      env: getHost(),
      isLoading: false,
      modeList: ["This month", "Last month"],
      selectedMode: "This month"
    };
  },
  computed: {},
  methods: {
    hideLoadingAtNextTick() {
      Vue.nextTick(() => {
        this.isLoading = false;
      });
    },
    viewReport() {
      //this.isLoading = true;
      var params = {
        DateGenerated: moment().format("DD-MMM-YYYY HH:mm"),
        CustomerId: store.getters.selectedCustomer.Id,
        CustomerName: store.getters.selectedCustomer.CustomerName,
        Mode: this.selectedMode,
        Client: this.env
      };

      var host = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
      var loader = `<html><head><link type="text/css" href="${host}/loader.css" rel="stylesheet"></head><body><div class="loading"><div class="heading">Your report is being generated</div><div class="loader"></div></div></body></html>`;
      let win = window.open("about:blank", "Tag Report");
      win.document.write(loader);

      axios
        .post("https://fema-reports.calmsmoke-626cd2e8.southafricanorth.azurecontainerapps.io/reports/tag", params, {
          responseType: "arraybuffer"
        })
        .then(resp => {
          var blob = new Blob([resp.data], {
            type: "application/pdf"
          });
          console.log("b");
          var blobUrl = window.URL.createObjectURL(blob);
          var html = `<html>

<style>
html,
body {
    padding: 0;
    margin: 0;
}

iframe {
    width: 100%;
    height: 100%;
    border: 0
}
</style>
<body><iframe type="application/pdf"src="${blobUrl}"></iframe></body></html>`;
          console.log(win.document.getElementsByTagName("body")[0]);
          console.log(win.document.getElementsByTagName("body")[0].innerHTML);
          win.document.getElementsByTagName("body")[0].innerHTML = "";
          win.document.write(html);
          win.document.close();
          console.log(win.document.getElementsByTagName("body")[0].innerHTML);

          win.onbeforeunload = function () {
            window.URL.revokeObjectURL(blobUrl);
          };
        })
        .catch(err => {
          win.close();
          console.log(err);
          this.$helpers.dispatch("showError", {
            msg: "Unexpected error occurred",
            vm: this
          });
        })
        .finally(f => {
          this.hideLoadingAtNextTick();
        });
    }
  }
};
</script>.card {
    z-index: auto;
}
</style>
