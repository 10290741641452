<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Add Area" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Area Name" v-model="area.AreaName" required />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label class="control-label">Site</label>
              <select-picker
                emptyText="No results"
                :dropdown-data="sitesSelectList"
                placeholder="None"
                v-model="area.SiteName"
                @change="siteChanged"
                required
                search
              />
            </div>
          </div>
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3 float-right">
              <p-button
                type="danger"
                round
                @click="cancel"
                fa="fal fa-times-octagon"
                class="mr-3"
              >Cancel</p-button>
              <p-button type="info" round fa="fal fa-save" required nativeType="submit">Save</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SelectPicker from "vue-bootstrap-selectpicker";

import { db, getCustomer } from "@/db";
import utils from "@/utils";
import store from "@/router/store";

export default {
  components: {
    Loading
  },
  props: {
    siteId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isLoading: false,
      area: {
        CustomerId: "",
        CustomerName: "",
        Id: "",
        SiteId: "",
        SiteName: "",
        AreaName: ""
      },
      allSites: [],
      sitesSelectList: []
    };
  },
  mounted() {
    this.area.Id = utils.newId();
    this.area.CustomerId = store.getters.selectedCustomer.Id;
    this.area.CustomerName = store.getters.selectedCustomer.CustomerName;
  },
  firestore() {
    return {
      allSites: getCustomer()
        .collection("sites")
        .orderBy("SiteName")
    };
  },
  watch: {
    allSites: {
      immediate: true,
      handler(allSites) {
        this.sitesSelectList = allSites.map(site => {
          return {
            value: site.Id,
            text: site.SiteName
          };
        });

        if (this.$props.siteId !== "" && allSites.length > 0) {
          this.area.SiteId = this.$props.siteId;
          this.area.SiteName = allSites.filter(
            s => s.Id == this.area.SiteId
          )[0].SiteName;
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;
      getCustomer()
        .collection("areas")
        .doc(this.area.Id)
        .set(this.area, { merge: true })
        .then(() => {
          this.$router.go(-1);
          this.$helpers.dispatch("showSuccess", {
            vm: this,
            msg: "Area created successfully"
          });
        })
        .catch(err => {
          this.isLoading = false;
          this.$helpers.dispatch("showError2", {
            vm: this,
            err: err
          });
        });
    },
    siteChanged({ data, text }) {
      if (!data[0]) {
        this.area.SiteId = "";
        this.area.SiteName = "";
      }

      this.area.SiteName = data[0].text;
      this.area.SiteId = data[0].value;
    }
  }
};
</script>
