<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Edit Customer" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Customer Name" v-model="customer.CustomerName" required />
            </div>
          </div>
          <div class="row" v-if="showAgent">
            <div class="col">
              <label class="control-label">Agent</label>
              <select-picker
                emptyText="No results"
                :dropdown-data="agentsSelectList"
                placeholder="None"
                v-model="customer.AssignedToAgentName"
                @change="agentChanged"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Description" v-model="customer.Description" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Address" v-model="customer.Address" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Contact person" v-model="customer.Person" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input type="text" label="Phone number" v-model="customer.PhoneNumber" />
            </div>
          </div>
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3">
              <p-button
                type="danger"
                round
                @click="cancel"
                fa="fal fa-times-octagon"
                class="mr-3"
              >Cancel</p-button>
              <p-button type="info" round fa="fal fa-save" nativeType="submit">Save</p-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { db, getCustomer } from "@/db";
import store from "@/router/store";
export default {
  components: {
    Loading
  },
  props: {
    Id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isLoading: false,
      showAgent: false,
      customer: {},
      allAgents: [],
      agentsSelectList: []
    };
  },
  firestore() {
    return {
      allAgents: db.collection("agents")
    };
  },
  mounted() {
    this.isLoading = true;
    this.showAgent = store.getters.loggedInUser.AssignedToAgentId === null;
    let self = this;
    db.collection("customers")
      .doc(this.$props.Id)
      .get()
      .then(u => {
        self.customer = u.data();
        self.isLoading = false;
      })
      .catch(err => {
        self.isLoading = false;
        self.$helpers.dispatch("showError2", {
          err: err,
          vm: self
        });
      });
  },
  watch: {
    allAgents: {
      immediate: true,
      handler(allAgents) {
        this.agentsSelectList = allAgents.map(agent => {
          return {
            value: agent.Id,
            text: agent.Name
          };
        });
      }
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;
      let self = this;

      db.collection("customers")
        .doc(this.$props.Id)
        .set(this.customer, { merge: true })
        .then(() => {
          self.$router.go(-1);
          self.$helpers.dispatch("showSuccess", {
            msg: "Customer updated successfully",
            vm: self
          });
        })
        .catch(err => {
          self.isLoading = false;
          self.$helpers.dispatch("showError", {
            msg: err.message ?? err,
            vm: self
          });
        });
    },
    agentChanged({ data, text }) {
      if (!data[0]) {
        this.customer.AssignedToAgentName = "";
        this.customer.AssignedToAgentId = "";
      }

      this.customer.AssignedToAgentName = data[0].text;
      this.customer.AssignedToAgentId = data[0].value;
    }
  }
};
</script>
<style scoped>
.bootstrap-select {
  width: 100% !important;
}
</style>

