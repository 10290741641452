<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :opacity="0.85"
      :is-full-page="true"
    ></loading>
    <div class="col-12">
      <card
        :title="title"
        :subTitle="subTitle"
        :showFilter="showFilter"
        :filterInputValue="filterTerm"
      >
        <div slot="raw-content" class="table-responsive">
          <paper-table
            :data="displayData"
            :loading="loading"
            :columns="columns"
            :hasActions="hasActions"
            :actions="actions"
            :totalItems="totalItems"
            @changePage="changePage"
            @deleteClick="deleteClick"
            customAction1="View Questions"
            @customAction1Clicked="viewQuestionsClicked"
          ></paper-table>
        </div>
        <div class="row">
          <div class="col text-right">
            <input
              id="typeCounts"
              type="checkbox"
              v-model="showTypeCounts"
              @click="toggleShowTypeCounts()"
              class="pull-right mt-3 ml-3"
            />
            <label for="typeCounts"> &nbsp;Show Type Counts</label>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import odata from "@/services/odata.vue";
import { PaperTable } from "@/components";
import { db, getCustomer } from "@/db";
import utils from "@/utils";
import store from "@/router/store";

const tableColumns = ["Name", "Count"];

export default {
  components: {
    PaperTable,
    Loading,
  },
  props: {},
  data() {
    return {
      showTypeCounts: false,
      loading: true,
      title: "Asset Types",
      subTitle: `All ${store.getters.selectedCustomer.CustomerName} asset types are listed below`,
      hasActions: true,
      actions: {
        edit: false,
        delete: false,
      },
      columns: [...tableColumns],
      allData: [],
      allAssets: [],
      displayData: [],
      filteredData: [],
      isLoading: false,
      currentPage: 1,
      totalItems: 1,
      showFilter: false,
      filterTerm: "",
      assetCountDict: {},
    };
  },
  watch: {
    allData: {
      immediate: true,
      handler(allData) {
        if (allData.length > 0) {
          this.loading = false;
        }

        this.filterData();
      },
    },
    allAssets: {
      immediate: true,
      handler(assets) {
        if (assets.length > 0)
          //this.populateAssetCountDict();
          this.filterData();
      },
    },
    filterTerm: {
      immediate: true,
      handler(filterTerm) {
        this.filterData();
      },
    },
  },
  firestore() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);

    return {
      allData: getCustomer().collection("assettypes"),
      allAssets: getCustomer().collection("assets"),
    };
  },
  methods: {
    loadPage(num) {
      this.isLoading = true;
      if (!num) num = this.currentPage;
      let take = 10;
      this.displayData = utils.paginate(this.filteredData, take, num);
      this.isLoading = false;
    },
    filterData() {
      var search = this.filterTerm.toUpperCase();
      var result = this.allData.filter((f) => {
        return f.AssetTypeName.toUpperCase().includes(search);
      });

      this.filteredData = result
        .sort(function (a, b) {
          return a.AssetTypeName.toUpperCase() > b.AssetTypeName.toUpperCase()
            ? 1
            : -1;
        })
        .map((c) => {
          return {
            Name: this.getTypeHtml(c.AssetTypeName, c.AssetTypeName),
            Count: this.getAssetCount(c.AssetTypeName),
            Id: c.Id,
          };
        });

      this.totalItems = this.filteredData.length;
      this.loadPage(1);
    },
    changePage(pageNum) {
      this.currentPage = pageNum;
      this.loadPage(pageNum);
    },
    viewQuestionsClicked(id) {
      this.$router.push(`/questions/${id}`);
    },
    deleteClick(id, description) {
      if (
        window.confirm(
          `Are you sure you want to delete the asset type '${description}'?`
        )
      ) {
        this.isLoading = true;
        let self = this;
        this.isLoading = true;
        getCustomer()
          .collection("assettypes")
          .doc(id)
          .delete()
          .then(() => {
            self.$helpers.dispatch("showSuccess", {
              msg: "Asset type deleted successfully",
              vm: self,
            });
          })
          .catch((err) => {
            self.isLoading = false;
            self.$helpers.dispatch("showError2", {
              vm: self,
              err: err,
            });
          });
      }
    },
    getAssetCount(typeName) {
      if (!this.assetCountDict[typeName]) {
        return this.showTypeCounts ? "0" : "";
      }

      return this.assetCountDict[typeName];
    },
    toggleShowTypeCounts() {
      this.showTypeCounts = !this.showTypeCounts;

      if (this.showTypeCounts) {
        this.allData.forEach((a) => {
          if (!this.assetCountDict[a.AssetTypeName]) {
            var count = this.allAssets.filter((f) => {
              return f.AssetTypeName === a.AssetTypeName;
            });

            this.assetCountDict[a.AssetTypeName] = count.length;
          }
        });
      } else {
        this.assetCountDict = {};
      }

      this.filterData();
    },
    getTypeHtml(type, tag) {
      switch (type) {
        case "Fire Panel":
            return `<i class="type-icon fal fa-light fa-panel-fire" title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "TUNNEL SPRINKLER BST":
          return `<i class='type-icon fab fa-empire' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Suppression System":
          return `<i class='type-icon fal fa-cloud-meatball' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Door":
          return `<i class='type-icon fal fa-door-open' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Kolomela Fire Hydrant":
          return `<i class='type-icon fal fa-tint' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Other":
          return `<i class='type-icon fal fa-map-marker-question' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Detection System":
          return `<i class='type-icon fal fa-thermometer-three-quarters' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Hose":
          return `<i class='type-icon fal fa-tape' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Hydrant":
          return `<i class='type-icon fal fa-tint' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Hose Reel":
          return `<i class='type-icon fal fa-tape' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Extinguisher":
          return `<i class='type-icon fal fa-fire-extinguisher' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "First Aid Box":
          return `<i class='type-icon fal fa-medkit' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Vehicle Fire Suppression":
          return `<i class='type-icon fal fa-truck-monster' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Diesel Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Water Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Electric Pump":
              return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Jockey Pump":
              return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
            default:
              return `<i class='type-icon fal fa-map-marker-question' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
      }
    },
  },
};
</script>

