<template>
<div class="card">
    <div class="card-image" v-if="$slots.image">
        <slot name="image"></slot>
    </div>
    <div class="card-header" v-if="$slots.header || title">
        <slot name="header">
            <div class="row">
                <div class="col">
                    <h4 class="card-title">{{title}}</h4>
                    <p class="card-category" v-if="subTitle" v-html="subTitle"></p>
                </div>
				<slot name="additional-header"></slot>
                <div class="col" v-show="showFilter">
                    <div class="row float-right">
                        <div class="mr-3">
                            <fg-input type="text" placeholder="Type to filter" @input="getInput"
							:value="filterInputValue" @enterPressed="doFilter" @escPressed="clearFilter"></fg-input>
                        </div>
                       <div class="mr-5">
                            <p-button type="primary" class="btn-action" @click="doFilter" round>Filter</p-button>
                        </div>
                    </div>

                </div>
            </div>

        </slot>
    </div>
    <div class="card-body" v-if="$slots.default">
        <slot></slot>
    </div>
    <slot name="raw-content"></slot>
    <div class="card-footer" v-if="$slots.footer">
        <hr>
        <slot name="footer"></slot>
    </div>
</div>
</template>

<script>
export default {
    name: "card",
    props: {
        title: String,
        subTitle: String,
        showFilter: {
            type: Boolean,
            default: true
		},
		filterInputValue: {
            type: String,
            default: ""
        }
    },
    methods: {
        doFilter() {
			this.$emit("doFilter", this.filterTerm)
		},
		clearFilter() {
			this.$emit("doFilter", "")
        },
        getInput(input) {
			this.filterTerm = input;
        }
	},
	data() {
		return {
			filterTerm: ""
		}
	}
};
</script>

<style>

</style>
