import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Home from "@/pages/Home.vue";

import Dashboard from "@/pages/Dashboard.vue";
import Login from "@/pages/Login.vue";
import ForgotPassword from "@/pages/ForgotPassword.vue";
import Landing from "@/pages/Landing.vue";

//clients
import ListClients from "@/pages/Clients/ListClients.vue";
import AddClient from "@/pages/Clients/AddClient.vue";
import EditClient from "@/pages/Clients/EditClient.vue";

//auditors
import ListAuditors from "@/pages/Auditors/ListAuditors.vue";
import AddAuditor from "@/pages/Auditors/AddAuditor.vue";
import EditAuditor from "@/pages/Auditors/EditAuditor.vue";

//customers
import ListCustomers from "@/pages/Customers/ListCustomers.vue";
import AddCustomer from "@/pages/Customers/AddCustomer.vue";
import EditCustomer from "@/pages/Customers/EditCustomer.vue";

//sites
import ListSites from "@/pages/Sites/ListSites.vue";
import AddSite from "@/pages/Sites/AddSite.vue";
import EditSite from "@/pages/Sites/EditSite.vue";

//agents
import ListAgents from "@/pages/Agents/ListAgents.vue";
import AddAgent from "@/pages/Agents/AddAgent.vue";
import EditAgent from "@/pages/Agents/EditAgent.vue";

//areas
import ListAreas from "@/pages/Areas/ListAreas.vue";
import AddArea from "@/pages/Areas/AddArea.vue";
import EditArea from "@/pages/Areas/EditArea.vue";

//assets
import ListAssets from "@/pages/Assets/ListAssets.vue";
import AddAsset from "@/pages/Assets/AddAsset.vue";
import EditAsset from "@/pages/Assets/EditAsset.vue";

//assetstypes
import ListAssetTypes from "@/pages/AssetTypes/ListAssetTypes.vue";

//services
import ListServices from "@/pages/Services/ListServices.vue";

//questions
import ListQuestions from "@/pages/Questions/ListQuestions.vue";
import AddQuestion from "@/pages/Questions/AddQuestion.vue";
import EditQuestion from "@/pages/Questions/EditQuestion.vue";

//audits
import ListAudits from "@/pages/Audits/ListAudits.vue";

//answers
import ListAnswers from "@/pages/Answers/ListAnswers.vue";

//reports
import ListReports from "@/pages/Reports/ListReports.vue";

//settings
import Settings from "@/pages/Settings.vue";

const routes = [
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			allowAnon: true
		}
	},
	{
		path: "/forgotpassword",
		name: "forgotpassword",
		component: ForgotPassword,
		meta: {
			allowAnon: true
		}
	},
	{
		path: "/landing",
		name: "landing",
		component: Landing,
		meta: {
			allowAnon: true
		}
	},
	{
		path: "/",
		component: DashboardLayout,
		redirect: "/home",
		children: [
			{
				path: "dashboard",
				name: "dashboard",
				component: Dashboard
			},
			{
				path: "home",
				name: "home",
				component: Home
			},
			//clients
			{
				path: "addclient",
				name: "addclient",
				component: AddClient
			},
			,
			{
				path: "clients",
				name: "clients",
				component: ListClients
			},
			{
				path: "editclient/:id",
				name: "editclient",
				component: EditClient,
				props: true
			},
			//auditors
			{
				path: "addauditor",
				name: "addauditor",
				component: AddAuditor
			},
			,
			{
				path: "auditors",
				name: "auditors",
				component: ListAuditors
			},
			{
				path: "editauditor/:id",
				name: "editauditor",
				component: EditAuditor,
				props: true
			},
			//customers
			{
				path: "customers",
				name: "listcustomers",
				component: ListCustomers
			},
			{
				path: "addcustomer",
				name: "addcustomer",
				component: AddCustomer
			},
			{
				path: "editcustomer/:Id",
				name: "editcustomer",
				component: EditCustomer,
				props: true
			},
			//sites
			{
				path: "sites",
				name: "listsites",
				component: ListSites
			},
			{
				path: "addsite",
				name: "addsite",
				component: AddSite
			},
			{
				path: "editsite/:siteId",
				name: "editsite",
				component: EditSite,
				props: true
			},
			//agents
			{
				path: "agents",
				name: "listagents",
				component: ListAgents
			},
			{
				path: "addagent",
				name: "addagent",
				component: AddAgent
			},
			{
				path: "editagent/:agentId",
				name: "editagent",
				component: EditAgent,
				props: true
			},
			//areas
			{
				path: "areas",
				name: "listareas",
				component: ListAreas
			},
			{
				path: "areas/:siteId",
				name: "listareaswithparam",
				component: ListAreas,
				props: true
			},
			{
				path: "editarea/:areaId",
				name: "editarea",
				component: EditArea,
				props: true
			},
			{
				path: "addarea/:siteId",
				name: "addarea2",
				component: AddArea,
				props: true
			},
			{
				path: "addarea",
				name: "addarea",
				component: AddArea
			},
			//assets
			{
				path: "assets",
				name: "listassets",
				component: ListAssets
			},
			{
				path: "assets/:areaId",
				name: "listassetswithparam",
				component: ListAssets,
				props: true
			},
			{
				path: "editassets/:Id",
				name: "editassets",
				component: EditAsset,
				props: true
			},
			{
				path: "addasset",
				name: "addasset",
				component: AddAsset
			},
			//assettypes
			{
				path: "assettypes",
				name: "listassettypes",
				component: ListAssetTypes
			},
			//services
			{
				path: "services",
				name: "services",
				component: ListServices
			},
			//questions
			{
				path: "questions",
				name: "questions",
				component: ListQuestions
			},
			{
				path: "questions/:assetTypeId",
				name: "questions2",
				component: ListQuestions,
				props: true
			},
			{
				path: "editquestion/:questionId",
				name: "editquestion",
				component: EditQuestion,
				props: true
			},
			{
				path: "addquestion/:assetTypeId/:orderNo",
				name: "addquestion",
				component: AddQuestion,
				props: true
			},
			//audits
			{
				path: "audits",
				name: "audits",
				component: ListAudits
			},
			//answers
			{
				path: "answers/:auditId",
				name: "listAnswersWithParam",
				component: ListAnswers,
				props: true
			},
			//reports
			{
				path: "reports",
				name: "reports",
				component: ListReports
			},
			//settings
			{
				path: "settings",
				name: "settings",
				component: Settings
			}
		]
	},
	{
		path: "*",
		component: NotFound
	}
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
