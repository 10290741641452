<template>
  <div class="row">
      <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
      <div class="col-12">
          <card :title="title" :subTitle="subTitle" :showFilter="showFilter" @doFilter="doFilter" :filterInputValue="filterTerm">
              <div slot="raw-content" class="table-responsive">
                  <div class="entity-table-container" id="divEntityTable">
                      <table class="table table-striped entity-table">
                          <thead>
                              <th style="width:20%">Asset</th>
                              <th style="width:15%">Site</th>
                              <th style="width:15%">Area</th>
                              <th style="width:10%">Last Audit</th>
                              <th style="width:10%">Last Service</th>
                              <th class="thAction">Actions</th>
                          </thead>
                          <tbody>
                              <tr v-for="(item, index) in displayData" :key="index" :class="item.Id">
                                  <td v-html="item['Asset']"></td>
                                  <td>{{ item["Site"] }}</td>
                                  <td>{{ item["Area"] }}</td>
                                  <td>{{ item["LastAudit"] }}</td>
                                  <td v-if="item.Id !== editingItem" @click="editServiceDate(item.Id)" class="pointer">
                                      <span class="editSpan" :id="`serviceSpan_${item.Id}`">
                                          {{ item["LastService"] }}
                                          <i class="far fa-edit edit-service-icon"></i>
                                      </span>

                                  </td>
                                  <td v-else>
                                      <span class="editSpan">
                                          <input v-model="item['LastServiceEdit']" style="width:85px; border: none" v-mask="'##/##/####'" :id="`editServiceInput_${item.Id}`" @keyup.esc="editingItem=''" @keyup.enter="saveServiceDate" :class="isSaving && savingTextbox" :readonly="isSaving" @blur="editingItem=''"/>
                                          <i v-show="isSaving" class="fa fa-spinner fa-spin edit-service-icon"></i>
                                      </span>
                                  </td>
                                  <td class="tdAction">
                                      <p-button type="danger" class="btn-action table-action-button" round @click="deleteClick(item.Id, item.Name)">Delete</p-button>
                                  </td>
                              </tr>
                              <tr v-if="displayData.length == 0 && !loading">
                                  <td colspan="5">No records found</td>
                              </tr>
                              <tr v-if="loading">
                                  <td colspan="5">
                                      Loading data, please wait...
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      <div class="row" style="align-items: center">
                          <div class="col-md align-middle">
                              <p-button v-show="showAdd" type="warning" class="btn-action table-action-button" round @click="$emit('addClick')">Add new {{ entityName }}</p-button>
                          </div>
                          <div class="pager-container col-md-auto">
                              <div is="uib-pagination" :boundary-links="true" :total-items="totalItems" @change="changePage" v-model="pagination" class="pagination-sm" previous-text="‹" next-text="›" first-text="«" last-text="»" :max-size="7"></div>
                          </div>
                      </div>
                  </div>
              </div>
          </card>
      </div>
  </div>
</template>

<script>
import Vue from "vue";
import { nextTick } from "vue";
import VueMask from "v-mask";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { PaperTable } from "@/components";
import { db, getCustomer } from "@/db";
import utils from "../../utils.js";
import store from "../../router/store";
import pagination from "vuejs-uib-pagination";

Vue.use(pagination);
Vue.use(VueMask);

const tableColumns = ["Asset", "Site", "Area", "Last Audit", "Last Service"];

export default {
  components: {
      PaperTable,
      Loading,
  },
  props: {
      areaId: {
          type: String,
          defualt: null,
      },
  },
  computed: {
      tableClass() {
          return `table-${this.type} entity-table`;
      },
  },
  data() {
      return {
          loading: true,
          showAdd: false,
          title: "Assets",
          subTitle: `All ${store.getters.selectedCustomer.CustomerName} assets in this area are listed below`,
          hasActions: true,
          actions: {
              edit: false,
              delete: true,
          },
          columns: [...tableColumns],
          allData: [],
          displayData: [],
          filteredData: [],
          isLoading: false,
          currentPage: 1,
          totalItems: 1,
          showFilter: true,
          filterTerm: "",
          editingRow: "",
          entityName: "Assets",
          pagination: {
              currentPage: 1,
          },
          editingItem: "",
          isSaving: false,
          savingTextbox: "textbox-saving",
      };
  },
  watch: {
      areaId: {
          immediate: true,
          handler(areaId) {
              let assets = areaId
                  ? getCustomer()
                        .collection("assets")
                        .where("AreaId", "==", this.$props.areaId)
                  : getCustomer().collection("assets");

              this.$bind("allData", assets);
          },
      },
      allData: {
          immediate: true,
          handler(allData) {
              if (allData.length > 0) {
                  this.loading = false;
              }

              this.filterData();
          },
      },
      filterTerm: {
          immediate: true,
          handler(filterTerm) {
              this.filterData();
          },
      },
  },
  firestore() {
      setTimeout(() => {
          this.loading = false;
      }, 3000);

      let assets = this.$props.areaId
          ? getCustomer()
                .collection("assets")
                .where("AreaId", "==", this.$props.areaId)
          : getCustomer().collection("assets");
      return {
          allData: assets,
      };
  },
  methods: {
      saveServiceDate(event) {
          this.isSaving = true;
          let self = this;
          var newDate = moment(event.target.value, "DD/MM/YYYY");
          var id = event.target.id.replace(/editServiceInput_/g, "");
          var spanId = `serviceSpan_${id}`;

          getCustomer()
              .collection("assets")
              .doc(id)
              .update({ LastServiceDateUnix: newDate.unix() })
              .then(() => {
                  self.editingItem = "";
                  self.isSaving = false;
              })
              .catch(async (err) => {
                  self.editingItem = "";
                  self.isSaving = false;

                  self.$helpers.dispatch("showError", {
                      msg: "Error saving Last Service",
                      vm: self,
                  });

                  await nextTick();
                  var span = document.getElementById(spanId);
                  span.classList.add("saving-error");
              });
      },
      async editServiceDate(itemId) {
          this.editingItem = itemId;
          await nextTick();
          var input = document.getElementById(`editServiceInput_${itemId}`);
          var span = input.parentElement;

          span.classList.remove("saving-error");
          input.focus();
          input.select();
      },
      hasValue(item, column) {
          return item[column.replace(/ /g, "")] !== "undefined";
      },
      loadPage(num) {
          this.isLoading = true;
          if (!num) num = this.currentPage;
          let take = 10;
          this.displayData = utils.paginate(this.filteredData, take, num);
          this.isLoading = false;
      },
      filterData() {
          var search = this.filterTerm.toUpperCase();
          var result = this.allData.filter((f) => {
              return (
                  f.AreaName.toUpperCase().includes(search) ||
                  f.SiteName.toUpperCase().includes(search) ||
                  f.AssetTypeName.toUpperCase().includes(search) ||
                  f.TagNo.toUpperCase().includes(search)
              );
          });

          this.filteredData = result
              .sort(function (a, b) {
                  return a.AreaName.toUpperCase() > b.AreaName.toUpperCase()
                      ? 1
                      : -1;
              })
              .map((c) => {
                  var LastServiceDate =
                      c.LastServiceDateUnix < 0
                          ? null
                          : c.LastServiceDateUnix;

                  var LastAuditDate =
                      c.LastAuditDateUnix < 0 ? null : c.LastAuditDateUnix;

                  return {
                      Asset: this.getTypeHtml(c.AssetTypeName, c.TagNo),
                      Site: c.SiteName,
                      Area: c.AreaName,
                      Id: c.Id,
                      TagNo: c.TagNo,
                      LastAudit: LastAuditDate
                          ? moment.unix(LastAuditDate).format("D-MMM-YYYY")
                          : "Never",
                      LastService: LastServiceDate
                          ? moment.unix(LastServiceDate).format("D-MMM-YYYY")
                          : "Never",
                      LastServiceEdit: LastServiceDate
                          ? moment.unix(LastServiceDate).format("DD-MM-YYYY")
                          : "Never",
                      Tag: c.Id,
                  };
              });

          this.totalItems = this.filteredData.length;
          this.loadPage(1);
      },
      changePage() {
          this.currentPage = this.$data.pagination.currentPage;
          this.loadPage(this.$data.pagination.currentPage);
      },
      doFilter(filter) {
          this.filterTerm = filter;
      },
      editClick(id) {
          this.$router.push(`/editasset/${id}`);
      },
      addClick(id) {
          this.$router.push(`/addasset`);
      },
      viewAuditsClicked(id) {
          //this.$router.push(`areas/${id}`)
      },
      async deleteClick(id, description) {
          if (
              window.confirm(
                  `Are you sure you want to decommission this asset and move it to the archive?`
              )
          ) {
              getCustomer().collection("assets").doc(id).delete();
          }
      },
      refreshData() {
          this.loadPage();
      },
      async hideLoadingAtNextTick() {
          await nextTick();
          this.isLoading = false;
      },
      getTypeHtml(type, tag) {
          switch (type) {
              case "Fire Panel":
                  return `<i class="type-icon fal fa-light fa-panel-fire" title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "TUNNEL SPRINKLER BST":
                  return `<i class='type-icon fab fa-empire' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Fire Suppression System":
                  return `<i class='type-icon fal fa-cloud-meatball' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Fire Door":
                  return `<i class='type-icon fal fa-door-open' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Kolomela Fire Hydrant":
                  return `<i class='type-icon fal fa-tint' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Other":
                  return `<i class='type-icon fal fa-map-marker-question' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Fire Detection System":
                  return `<i class='type-icon fal fa-thermometer-three-quarters' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Fire Hose":
                  return `<i class='type-icon fal fa-tape' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Fire Hydrant":
                  return `<i class='type-icon fal fa-tint' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Fire Hose Reel":
                  return `<i class='type-icon fal fa-tape' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Fire Extinguisher":
                  return `<i class='type-icon fal fa-fire-extinguisher' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "First Aid Box":
                  return `<i class='type-icon fal fa-medkit' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Vehicle Fire Suppression":
                  return `<i class='type-icon fal fa-truck-monster' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Diesel Pump":
                  return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Water Pump":
                  return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Electric Pump":
                  return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              case "Jockey Pump":
                  return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
              default:
                  return `<i class='type-icon fal fa-map-marker-question' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
          }
      },
  },
};
</script>

<style>
.type-icon {
  font-size: 25px;
  color: #444444;
  width: 30px;
}

.tagnumber {
  margin-left: 10px;
}
</style>

<style>
.button-row {
  margin: 0 30px 0 30px;
}

.entity-table-container {
  padding: 0 20px 0 20px;
}

.container {
  padding-top: 10px;
}

.pagination {
  display: flex;
}

.table-action-button {
  min-width: 90px;
  margin: 0 5px;
}
</style>

<style lang="scss">
$table-line-color: #ccc5b9 !default;
$muted-color: #a49e93 !default;
$medium-pale-bg: #f1eae0 !default;
$dark-gray: #9a9a9a !default;
$black-hr: #444444 !default;

.pager-container .pagination > li > a:focus,
.pager-container .pagination > li > a:hover,
.pager-container .pagination > li > span:focus,
.pager-container .pagination > li > span:hover {
  z-index: 3;
  color: $medium-pale-bg;
  background-color: $table-line-color;
  border-color: $muted-color;
}

.pager-container .pagination > .active > a,
.pager-container .pagination > .active > a:focus,
.pager-container .pagination > .active > a:hover,
.pager-container .pagination > .active > span,
.pager-container .pagination > .active > span:focus,
.pager-container .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: $dark-gray;
  border-color: $muted-color;
}

.edit-service-icon {
  font-size: 15px;
}

.pointer {
  cursor: pointer;
}

.editSpan {
  display: flex;
  justify-content: space-between;
  width: 9.5em;
  align-items: center;
  padding: 0.5em;
}

.textbox-saving {
  color: #9a9a9a;
}

.saving-error {
  background-color: #ffe4e1;
}
</style>