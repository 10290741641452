<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-12">
      <card :title="title" :subTitle="subTitle" :showFilter="showFilter">
        <div slot="additional-header" class="col">
          <div class="container-fluid">
            <div style="display:inline-block;margin-right:10px;">Asset type</div>
            <select-picker
              :dropdown-data="assetTypeList"
              placeholder="None"
              v-model="selectedAssetTypeName"
              @change="assetTypeChanged"
              search
              style="display:inline-block !important"
            />
          </div>
        </div>
        <div slot="raw-content" class="table-responsive">
          <paper-table
            :data="displayData"
            :sorting="true"
            :loading="loading"
            :columns="columns"
            :hasActions="hasActions"
            :actions="actions"
            :totalItems="totalItems"
            entityName="Question"
            @sortUp="sortUpClicked"
            @sortDown="sortDownClicked"
            @addClick="addClick"
            @editClick="editClick"
            @changePage="changePage"
            @deleteClick="deleteClick"
          />
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import odata from "@/services/odata.vue";
import moment from "moment";
import SelectPicker from "vue-bootstrap-selectpicker";
import { db, getCustomer } from "@/db";
import utils from "@/utils";
import store from "@/router/store";
import { PaperTable } from "@/components";

const tableColumns = ["Question", "Type", "Expected Answer", "Required"];

export default {
  components: {
    PaperTable,
    Loading
  },
  props: {
    assetTypeId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: true,
      title: "Questions",
      subTitle: `All ${store.getters.selectedCustomer.CustomerName} questions are listed below`,
      hasActions: true,
      sorting: true,
      actions: {
        edit: true,
        delete: true
      },
      columns: [...tableColumns],
      allData: [],
      displayData: [],
      filteredData: [],
      isLoading: false,
      currentPage: 1,
      totalItems: 1,
      showFilter: false,
      filterTerm: "",
      selectedAssetType: {},
      selectedAssetTypeName: "",
      selectedAssetTypeId: "",
      allAssetTypes: [],
      assetTypeList: []
    };
  },
  watch: {
    assetTypeId: {
      immediate: true,
      handler(assetTypeId) {
        let questions = assetTypeId
          ? getCustomer()
              .collection("questions")
              .where("AssetTypeId", "==", this.$props.assetTypeId)
          : getCustomer().collection("questions");

        let at = getCustomer()
          .collection("assettypes")
          .doc(this.$props.assetTypeId);

        this.loading = true;
        this.$bind("allData", questions);
        this.$bind("selectedAssetType", at);
      }
    },
    allData: {
      immediate: true,
      handler(allData) {
        if (allData.length > 0) {
          this.loading = false;
        }

        this.filterData();
      }
    },
    selectedAssetType: {
      immediate: false,
      handler(at) {
        if (at) {
          this.selectedAssetTypeName = this.selectedAssetType.AssetTypeName;
          this.selectedAssetTypeId = this.selectedAssetType.Id;
        }
      }
    },
    filterTerm: {
      immediate: true,
      handler(filterTerm) {
        this.filterData();
      }
    },
    allAssetTypes: {
      immediate: true,
      handler(ats) {
        this.assetTypeList = this.allAssetTypes.map(val => {
          return {
            text: val.AssetTypeName,
            value: val.Id
          };
        });
      }
    }
  },
  firestore() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);

    let questions = this.$props.assetTypeId
      ? getCustomer()
          .collection("questions")
          .where("AssetTypeId", "==", this.$props.assetTypeId)
      : getCustomer().collection("questions");
    return {
      allData: questions,
      allAssetTypes: getCustomer()
        .collection("assettypes")
        .orderBy("AssetTypeName")
    };
  },
  methods: {
    loadPage(num) {
      this.isLoading = true;
      if (!num) num = this.currentPage;
      let take = 10;
      this.displayData = utils.paginate(this.filteredData, take, num);
      this.isLoading = false;
    },
    filterData() {
      this.filteredData = this.allData
        .map(c => {
          return {
            Id: c.Id,
            OrderNo: c.OrderNo,
            Question: `${c.OrderNo}) ${c.Text}`,
            Type: c.Type == 1 ? "Yes/No" : "Text",
            ExpectedAnswer: c.Type == 1 ? c.ExpectedAnswer : "",
            Required: c.RequiredToAnswer ? "Yes" : "No"
          };
        })
        .sort(function(a, b) {
          return a.OrderNo > b.OrderNo ? 1 : -1;
        });

      this.totalItems = this.filteredData.length;
      this.loadPage(1);
    },
    sortUpClicked(orderNo) {
      var currentId = this.filteredData[orderNo - 1].Id;
      var previousId = this.filteredData[orderNo - 2].Id;

      getCustomer()
        .collection("questions")
        .doc(previousId)
        .update({ OrderNo: orderNo });

      getCustomer()
        .collection("questions")
        .doc(currentId)
        .update({ OrderNo: orderNo - 1 });

      this.loadPage(1);
    },
    sortDownClicked(orderNo) {
      var currentId = this.filteredData[orderNo - 1].Id;
      var nextId = this.filteredData[orderNo].Id;

      getCustomer()
        .collection("questions")
        .doc(nextId)
        .update({ OrderNo: orderNo });

      getCustomer()
        .collection("questions")
        .doc(currentId)
        .update({ OrderNo: orderNo + 1 });

      this.loadPage(1);
    },
    changePage(pageNum) {
      this.currentPage = pageNum;
      this.loadPage(pageNum);
    },
    addClick() {
      this.$router.push(
        `/addquestion/${this.selectedAssetTypeId}/${this.allData.length + 1}`
      );
    },
    editClick(id) {
      this.$router.push(`/editquestion/${id}`);
    },
    deleteClick(id) {
      if (window.confirm(`Are you sure you want to delete this question?`)) {
        var thisQuestion = this.allData.filter(f => f.Id === id)[0];
        this.allData
          .filter(f => f.OrderNo > thisQuestion.OrderNo)
          .forEach(q => {
            let newOrder = q.OrderNo - 1;
            getCustomer()
              .collection("questions")
              .doc(q.Id)
              .update({ OrderNo: newOrder });
          });
        getCustomer()
          .collection("questions")
          .doc(id)
          .delete();
      }
    },
    refreshData() {
      this.loadPage();
    },
    assetTypeChanged({ data, text }) {
      this.$router.push(`/questions/${data[0].value}/`);
    }
  }
};
</script>

<style>
</style>
