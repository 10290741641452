<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :opacity="0.85"
      :is-full-page="true"
    ></loading>
    <div class="col-auto col-lg-8 col-xl-6">
      <card title="Edit Site" :subTitle="site.SiteName" :showFilter="false">
        <form @submit.prevent="save">
          <div class="row">
            <div class="col">
              <fg-input
                type="text"
                label="Name"
                v-model="site.SiteName"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fg-input
                type="text"
                label="Notify these email addresses when an audits fails on this site:"
                v-model="site.Email"
              />
              <small style="color:#9A9A9A">Seperate multiple addresses with a semicolon</small>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col ml-1">
              <input
                id="Archived"
                type="checkbox"
                v-model="site.Archived"
              />
              <label for="Archived">
                &nbsp;Site is Archived
              </label>
            </div>
          </div>
          <div class="row float-right mt-5">
            <div class="col mt-4 mb-3">
              <p-button
                type="danger"
                round
                @click="cancel"
                fa="fal fa-times-octagon"
                class="mr-3"
                >Cancel</p-button
              >
              <p-button type="info" round fa="fal fa-save" nativeType="submit"
                >Save</p-button
              >
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>

<script>
import odata from "@/services/odata.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { db, getCustomer } from "@/db";

export default {
  components: {
    Loading,
  },
  props: {
    siteId: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.isLoading = true;
    let self = this;
    getCustomer()
      .collection("sites")
      .doc(this.$props.siteId)
      .get()
      .then((u) => {
        self.site = u.data();
        self.isLoading = false;
      })
      .catch((err) => {
        self.isLoading = false;
        self.$helpers.dispatch("showError2", {
          err: err,
          vm: self,
        });
      });
  },
  data() {
    return {
      isLoading: false,
      site: {},
    };
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    save() {
      this.isLoading = true;
      let self = this;

      getCustomer()
        .collection("sites")
        .doc(this.$props.siteId)
        .set(this.site, { merge: true })
        .then(() => {
          self.$router.go(-1);
          self.$helpers.dispatch("showSuccess", {
            msg: "Site updated successfully",
            vm: self,
          });
        })
        .catch((err) => {
          self.isLoading = false;
          self.$helpers.dispatch("showError", {
            msg: err.message ?? err,
            vm: self,
          });
        });
    },
  },
};
</script>
