<template>
	<div class="row">
		<loading
			:active.sync="isLoading"
			:can-cancel="true"
			:opacity="0.85"
			:is-full-page="true"
		></loading>
		<div class="col-auto col-lg-8 col-xl-6">
			<card
				title="General Settings"
				subTitle="You can change your general settings below"
				:showFilter="false"
			>
				<form @submit.prevent="save">
					<div class="row">
						<div class="col">
							<fg-input
								type="text"
								label="Notify these email addresses when any audits fails on any site:"
								v-model="auditFailedEmail"
							></fg-input>
							<small style="color: #9a9a9a"
								>Seperate multiple addresses with a
								semicolon</small
							>
						</div>
					</div>
				</form>
			</card>
			<card
				title="Automated Progress Report Settings"
				:showFilter="false"
			>
				<form @submit.prevent="save">
					<div class="row">
						<div class="col-lg-6">
							<label class="control-label">Send reports on:</label>
							<multiselect
								v-model="selectedWeekday"
								:options="weekdays"
								placeholder="Select weekday"
								:showLabels="false"
							/>
						</div>
						<div
							class="col-lg-1"
							style="align-self: self-end;text-align: center;padding-bottom: 15px;"
						>
							At
						</div>

						<div class="col-lg-5">
							<label class="control-label">&nbsp;</label>
							<multiselect
								v-model="selectedTimeofDay"
								:options="times"
								placeholder="Select time of day"
								:showLabels="false"
							/>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col">
							<fg-input
								type="text"
								label="Send reports to:"
								v-model="automatedAuditReportEmail"
							></fg-input>
							<small style="color: #9a9a9a"
								>Seperate multiple addresses with a
								semicolon</small
							>
						</div>
					</div>
				</form>
			</card>
			<div class="col mt-4 mb-3 text-lg-right">
				<p-button
					type="danger"
					round
					@click="cancel"
					class="mr-3"
					fa="fal fa-times-octagon"
					>Cancel</p-button
				>
				<p-button
					type="info"
					fa="fal fa-save"
					round
					nativeType="button"
					@click="save()"
					>Save</p-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { getCustomer } from "@/db";

export default {
	components: {
		Loading
	},
	mounted() {
		this.isLoading = true;
		let self = this;
		getCustomer()
			.collection("settings")
			.doc("audit-failed-email")
			.get()
			.then(doc => {
				self.isLoading = false;
				if (doc.exists) self.auditFailedEmail = doc.data().value;
			})
			.catch(err => {
				self.isLoading = false;
				self.$helpers.dispatch("showError2", {
					err: err,
					vm: self
				});
			});

		getCustomer()
			.collection("settings")
			.doc("automated-auditreport-email")
			.get()
			.then(doc => {
				self.isLoading = false;
				if (doc.exists)
					self.automatedAuditReportEmail = doc.data().value;
			})
			.catch(err => {
				self.isLoading = false;
				self.$helpers.dispatch("showError2", {
					err: err,
					vm: self
				});
			});

		getCustomer()
			.collection("settings")
			.doc("automated-auditreport-hour")
			.get()
			.then(doc => {
				self.isLoading = false;
				if (doc.exists) self.selectedTimeofDay = doc.data().value;
			})
			.catch(err => {
				self.isLoading = false;
				self.$helpers.dispatch("showError2", {
					err: err,
					vm: self
				});
			});

		getCustomer()
			.collection("settings")
			.doc("automated-auditreport-weekday")
			.get()
			.then(doc => {
				self.isLoading = false;
				if (doc.exists) self.selectedWeekday = doc.data().value;
			})
			.catch(err => {
				self.isLoading = false;
				self.$helpers.dispatch("showError2", {
					err: err,
					vm: self
				});
			});
	},
	data() {
		return {
			isLoading: false,
			auditFailedEmail: "",
			automatedAuditReportEmail: "",
			selectedWeekday: "Fridays",
			selectedTimeofDay: "09h00",
			times: [
				"00h00",
				"01h00",
				"02h00",
				"03h00",
				"04h00",
				"05h00",
				"06h00",
				"07h00",
				"08h00",
				"09h00",
				"10h00",
				"11h00",
				"12h00",
				"13h00",
				"14h00",
				"15h00",
				"16h00",
				"17h00",
				"18h00",
				"19h00",
				"20h00",
				"21h00",
				"22h00",
				"23h00"
			],
			weekdays: [
				"Mondays",
				"Tuesdays",
				"Wednesdays",
				"Thursdays",
				"Fridays",
				"Saturdays"
			]
		};
	},
	methods: {
		cancel() {
			this.$router.go(-1);
		},
		save() {
			this.isLoading = true;
			let self = this;
			var promises = [];

			promises.push(
				getCustomer()
					.collection("settings")
					.doc("audit-failed-email")
					.set({ value: self.auditFailedEmail }, { merge: true })
			);

			promises.push(
				getCustomer()
					.collection("settings")
					.doc("automated-auditreport-email")
					.set(
						{ value: self.automatedAuditReportEmail },
						{ merge: true }
					)
			);

			promises.push(
				getCustomer()
					.collection("settings")
					.doc("automated-auditreport-weekday")
					.set({ value: self.selectedWeekday }, { merge: true })
			);

			promises.push(
				getCustomer()
					.collection("settings")
					.doc("automated-auditreport-hour")
					.set({ value: self.selectedTimeofDay }, { merge: true })
			);

			Promise.all(promises)
				.then(() => {
					self.$router.go(-1);
					self.$helpers.dispatch("showSuccess", {
						msg: "Settings updated successfully",
						vm: self
					});
				})
				.catch(err => {
					self.isLoading = false;
					self.$helpers.dispatch("showError", {
						msg: err.message ?? err,
						vm: self
					});
				});
		}
	}
};
</script>
