import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "./store.js";

Vue.use(VueRouter);

let router = new VueRouter({
	routes,
	linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
	let loggedIn = store.getters.isLoggedIn;
	if (to.matched.some(record => record.meta.allowAnon)) {
		next();
		return;
	} else if (!loggedIn) {
		next("/landing");
	} else {
		next();
		return;
	}
});

export default router;
