<template>
    <div class="row">
        <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
        <div class="col-12">
            <card :title="title" :subTitle="subTitle" :showFilter="showFilter" :filterInputValue="filterTerm">
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="data" :columns="columns" :hasActions="hasActions" :actions="actions" :showPaging="false">
                    </paper-table>
                </div>
            </card>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import odata from "@/services/odata.vue";
import {
    PaperTable
} from "@/components";

const tableColumns = ["Question", "Answer", "Passed"];

export default {
    components: {
        PaperTable,
        Loading
    },
    props: {
        auditId: {
            type: String,
            defualt: null
        }
    },
    data() {
        return {
            title: "Audit Answers",
            subTitle: "All the answers given for a specific audit",
            hasActions: false,
            actions: {

            },
            columns: [...tableColumns],
            data: [],
            isLoading: false,
            showFilter: false,
            filterTerm: ""
        };
    },
    mounted() {
        this.loadPage();
    },
    methods: {
        hideLoadingAtNextTick() {
            Vue.nextTick(() => {
                this.isLoading = false;
            });
        },
        getResultHtml(result) {
            switch (result) {
                case true:
                    return "<i class='type-icon far fa-check icon-success' title='Passed'></i>";
                case false:
                    return "<i class='type-icon far far fa-times icon-danger' title='Failed'></i>";
            }
        },
        getPassedStatus(val) {
            if (!val.question.requiredToAnswer)
                return true;

            if (val.question.type === 2) { //free text
                return val.question.expectedAnswer === "" || val.question.expectedAnswer.toUpperCase() === val.freeText.toUpperCase();
            } else if (val.question.expectedAnswer.toUpperCase() === "YES") {
                return val.checked;
            }
            return !val.checked;
        },
        loadPage() {
            this.isLoading = true;
            let params = {
                entity: "answer",
                path: `/byaudit/${this.$props.auditId}`,
                orderby: 'Question/OrderNo',
                expand: 'Question'
            };

            odata.getPaged(params)
                .then(resp => {
                    this.data = resp.data.results.map(val => {
                        return {
                            question: val.question.text,
                            answer: val.question.type === 2 ? val.freeText : (val.checked ? "Yes" : "No"),
                            passed: this.getResultHtml(this.getPassedStatus(val))
                        };
                    });
                    this.hideLoadingAtNextTick();
                })
                .catch(err => {
                    this.hideLoadingAtNextTick();
                    if (err.response.status === 401) return;
                    this.$helpers.dispatch("showError", {
                        msg: "Unexpected error occurred",
                        vm: this
                    });
                });
        }
    }
}
</script>
