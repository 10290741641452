<template>
  <div class="row">
    <loading :active.sync="isLoading" :can-cancel="true" :opacity="0.85" :is-full-page="true"></loading>
    <div class="col-12">
      <card
        :title="title"
        :subTitle="subTitle"
        :showFilter="showFilter"
        @doFilter="doFilter"
        :filterInputValue="filterTerm"
      >
        <div slot="raw-content" class="table-responsive">
          <paper-table
            :data="data"
            :columns="columns"
            :hasActions="hasActions"
            :actions="actions"
            :totalItems="totalItems"
            @changePage="changePage"
            entityName
            customAction1="View Answers"
            @customAction1Clicked="viewAnswersClicked"
          />
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import odata from "@/services/odata.vue";
import moment from "moment";
import { PaperTable } from "@/components";

const tableColumns = ["Result", "Asset", "Site", "Area", "Date"];

export default {
  components: {
    PaperTable,
    Loading
  },
  props: {},
  watch: {
    areaId: function() {
      this.loadPage(1);
    }
  },
  data() {
    return {
      title: "Audits",
      subTitle: `All ${store.getters.selectedCustomer.CustomerName} audits are listed below`,
      hasActions: true,
      actions: {},
      columns: [...tableColumns],
      data: [],
      isLoading: false,
      currentPage: 1,
      totalItems: 1,
      showFilter: true,
      filterTerm: ""
    };
  },
  mounted() {
    this.loadPage(1);
  },
  methods: {
    changePage(pageNum) {
      this.currentPage = pageNum;
      this.loadPage(pageNum);
    },
    doFilter(filter) {
      this.filterTerm = filter;
      this.loadPage(1);
    },
    viewAnswersClicked(auditId) {
      this.$router.push(`answers/${auditId}`);
    },
    refreshData() {
      this.loadPage();
    },
    hideLoadingAtNextTick() {
      Vue.nextTick(() => {
        this.isLoading = false;
      });
    },
    getTypeHtml(type, tag) {
      switch (type) {
        case "Fire Panel":
            return `<i class="type-icon fal fa-light fa-panel-fire" title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "TUNNEL SPRINKLER BST":
          return `<i class='type-icon fab fa-empire' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Suppression System":
          return `<i class='type-icon fal fa-cloud-meatball' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Door":
          return `<i class='type-icon fal fa-door-open' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Kolomela Fire Hydrant":
          return `<i class='type-icon fal fa-tint' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Other":
          return `<i class='type-icon fal fa-map-marker-question' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Detection System":
          return `<i class='type-icon fal fa-thermometer-three-quarters' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Hose":
          return `<i class='type-icon fal fa-tape' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Hydrant":
          return `<i class='type-icon fal fa-tint' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Hose Reel":
          return `<i class='type-icon fal fa-tape' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Fire Extinguisher":
          return `<i class='type-icon fal fa-fire-extinguisher' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "First Aid Box":
          return `<i class='type-icon fal fa-medkit' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Vehicle Fire Suppression":
          return `<i class='type-icon fal fa-truck-monster' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Diesel Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        case "Water Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
		case "Electric Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
		case "Jockey Pump":
          return `<i class='type-icon fal fa-starship-freighter' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
        default:
          return `<i class='type-icon fal fa-map-marker-question' title='${type}'></i><span class='tagnumber'>${tag}</span>`;
      }
    },
    getResultHtml(result) {
      switch (result) {
        case "Passed":
          return "<i class='type-icon far fa-check icon-success' title='Passed'></i>";
        case "Failed":
          return "<i class='type-icon far far fa-times icon-danger' title='Failed'></i>";
      }
    },
    loadPage(num) {
      if (!num) num = this.currentPage;

      this.isLoading = true;
      let params = {
        entity: "audit",
        path: "",
        perPage: 10,
        page: num,
        orderby: "DateCompleted desc",
        filterFields: [
          "AssetId",
          "Asset/Area/Description",
          "PassedText",
          "Asset/Area/Site/Description",
          "Asset/AssetType/Description"
        ],
        filterValue: this.filterTerm,
        select: [
          "Id",
          "AssetId",
          "TagNo",
          "DateCompleted",
          "PassedText",
          "Asset/Area/Description",
          "Asset/Area/Site/Description",
          "Asset/AssetType/Description"
        ],
        expand: "Asset,Asset/Area,Asset/Area/Site,Asset/AssetType"
      };

      odata
        .getPaged(params)
        .then(resp => {
          this.totalItems = resp.data.count;
          this.data = resp.data.results.map(val => {
            return {
              asset: this.getTypeHtml(
                val.asset.assetType.description,
                val.tagNo
              ),
              site: val.asset.area.site.description,
              area: val.asset.area.description,
              id: val.id,
              tagNo: val.tagNo,
              date: moment(val.dateCompleted).format("D-MMM-YYYY"),
              tag: val.assetId,
              result: this.getResultHtml(val.passedText)
            };
          });
          this.hideLoadingAtNextTick();
        })
        .catch(err => {
          this.hideLoadingAtNextTick();
          if (err.response.status === 401) return;
          this.$helpers.dispatch("showError", {
            msg: "Unexpected error occurred",
            vm: this
          });
        });
    }
  }
};
</script>

<style>
.type-icon {
  font-size: 25px;
  color: #444444;
  width: 30px;
}

.tagnumber {
  margin-left: 10px;
}
</style>
