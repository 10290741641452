<template>
  <div style="margin: 20px;">Loading, please wait...</div>
</template>

<script>
export default {};
</script>

<style>
</style>
