<template>
  <div class="ml-2">
    <div class="row mt-5">
      <div class="col-lg-5 col-md-10 col-sm-12 text-right">
        <button class="btn btn-action btn-round btn-primary" @click="viewReport">View</button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment";
import store from "@/router/store";
import { getHost } from "../../env"

export default {
  components: {},
  data() {
    return {
      env: getHost(),
      isLoading: false
    };
  },
  computed: {},
  methods: {
    hideLoadingAtNextTick() {
      Vue.nextTick(() => {
        this.isLoading = false;
      });
    },
    viewReport() {
      //this.isLoading = true;
      var params = {
        CustomerId: store.getters.selectedCustomer.Id,
        CustomerName: store.getters.selectedCustomer.CustomerName,
        Client: this.env
      };

      var host = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
      var loader = `<html><head><link type="text/css" href="${host}/loader.css" rel="stylesheet"></head><body><div class="loading"><div class="heading">Your report is being generated</div><div class="loader"></div></div></body></html>`;
      let win = window.open("about:blank", "Progress Report");
      win.document.write(loader);

      axios
        .post("https://fema-reports.calmsmoke-626cd2e8.southafricanorth.azurecontainerapps.io/reports/progress", params, {
          responseType: "arraybuffer"
        })
        .then(resp => {
          var blob = new Blob([resp.data], {
            type: "application/pdf"
          });
          var blobUrl = window.URL.createObjectURL(blob);
          var html = `<html>
<style>
html,
body {
    padding: 0;
    margin: 0;
}

iframe {
    width: 100%;
    height: 100%;
    border: 0
}
</style><body><iframe type="application/pdf" src="${blobUrl}"></iframe></body></html>`;
          win.document.getElementsByTagName("body")[0].innerHTML = "";
          win.document.write(html);
          win.document.close();
          win.onbeforeunload = function () {
            window.URL.revokeObjectURL(blobUrl);
          };
        })
        .catch(err => {
          win.close();
          this.$helpers.dispatch("showError", {
            msg: "Unexpected error occurred",
            vm: this
          });
        })
        .finally(f => {
          this.hideLoadingAtNextTick();
        });
    }
  }
};
</script>

<style scoped>
.card {
  z-index: auto;
}
</style>
