<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <div
        class="mr-3 ml-4"
        v-show="!showCustomerSelectList()"
      >FEMA&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Fire Equipment Management and Auditing</div>
      <div class="mr-3 ml-4" v-show="showCustomerSelectList()">{{selectDescription}}</div>
      <select-picker
        :dropdown-data="customerList"
        placeholder="None"
        v-model="defaultSelectText"
        @change="changeSelect"
        search
        v-show="showCustomerSelectList()"
      />

      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a href="#" class="nav-link" @click="logout">
              <i class="fal fa-sign-out-alt"></i>
              <p>Log Out</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Vue from "vue";
import "vue-bootstrap-selectpicker/dist/css/vue-bootstrap-selectpicker.min.css";
import SelectPicker from "vue-bootstrap-selectpicker";
import odata from "@/services/odata.vue";
import axios from "axios";
import store from "@/router/store.js";
import { db, getCustomer } from "@/db";

Vue.use(SelectPicker);

export default {
  watch: {
    customerList: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        var customerName = localStorage.getItem("customerName");
        this.defaultSelectText = customerName;
      }
    }
  },
  mounted() {
    this.selectDescription = this.isInAnyRole(["Root", "Admin"])
      ? "Select Customer (Agent):"
      : "Select customer:";
  },
  computed: {
    routeName() {
      return this.$route.meta.title;
    }
  },
  data() {
    return {
      selectDescription: "",
      activeNotifications: false,
      defaultSelectText: "",
      customerList: [],
      allCustomers: []
    };
  },
  firestore() {
    var role = store.getters.userRole;
    var user = store.getters.loggedInUser;
    var custs = null;

    if (role === "Agent Admin") {
      custs = db
        .collection("customers")
        .where("AssignedToAgentId", "==", user.AssignedToAgentId)
        .orderBy("CustomerName");
    } else if (role === "Admin" || role === "Root") {
      custs = db.collection("customers").orderBy("CustomerName");
    } else if (role === "Client") {
      custs = db.collection("customers").where("Id", "==", user.AssignedToCustomerId);
    }


    return {
      allCustomers: custs
    };
  },
  watch: {
    allCustomers: {
      immediate: true,
      handler(allCustomers) {
        if (allCustomers.length > 0) {
          this.customerList = allCustomers.map(cust => {
            var txt = this.isInAnyRole(["Root", "Admin"])
              ? `${cust.CustomerName} (${cust.AssignedToAgentName})`
              : `${cust.CustomerName}`;
            return {
              text: txt,
              value: cust
            };
          });

          this.$store.dispatch("selectCustomer", this.customerList[0].value);
          this.defaultSelectText = this.customerList[0].text;
          this.$emit("showLoading", false);
        }
      }
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout: function () {
      this.$store.dispatch("logout");
    },
    isInRole(role) {
      return store.getters.userRole === role;
    },
    showCustomerSelectList() {
      return (
        this.isInAnyRole(["Root", "Admin", "Agent Admin"]) &&
        !this.$route.path.includes("/agents") &&
        !this.$route.path.includes("/addagent") &&
        !this.$route.path.includes("/editagent") &&
        !this.$route.path.includes("/editcustomer") &&
        !this.$route.path.includes("/addcustomer") &&
        !this.$route.path.includes("/customers")
      );
    },
    isInAnyRole(roles) {
      var returnVal = false;
      roles.forEach(r => {
        if (this.isInRole(r)) returnVal = true;
      });
      return returnVal;
    },
    changeSelect({ data, text }) {
      this.defaultSelectText = text;
      this.$store.dispatch("selectCustomer", data[0].value);
      this.$router.push("/home").catch(err => { });
    }
  }
};
</script>

<style>
.dropdown-item.active {
  background-color: #f38e24 !important;
  color: #f1eae0 !important;
}

.dropdown .dropdown-menu .dropdown-item.active {
  color: #f1eae0 !important;
}

.bootstrap-select {
  width: 30% !important;
  min-width: 400px !important;
}
</style>
